import { useAppDispatch } from 'redux/hooks';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useDeployModuleModal from './hooks/useDeployModuleModal';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import {
  ButtonField,
  ButtonGroupItem,
  InputField,
} from '@wk/components-react16';
import styles from '../../DeployModuleModal.module.scss';
import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import {
  IDeployModuleNames,
  IDeployModuleSearchRow,
  IDeployModuleServerRow,
} from 'interfaces/modules/module.interface';

interface ISelectModuleSection {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setEnableIndex: Dispatch<SetStateAction<number>>;
  setModules: Dispatch<SetStateAction<IDeployModuleSearchRow[] | undefined>>;
  setServers: Dispatch<SetStateAction<IDeployModuleServerRow[] | undefined>>;
  setIsLoading: (state: boolean) => void;
}

export default function SelectModuleSection({
  register,
  watch,
  setEnableIndex,
  setModules,
  setServers,
  setValue,
  setIsLoading,
}: ISelectModuleSection) {
  const name = watch('name');
  const version = watch('version');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const dispatch = useAppDispatch();

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsButtonDisabled(
      e.target.value.trim() === '' || e.target.value?.length < 3
    );
  };

  const { searchModule, loading } = useDeployModuleModal(name, version, {
    dispatch,
    setEnableIndex,
    setModules,
    setServers,
    setValue,
    register,
  });

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  return (
    <CsbErrorBoundary>
      <span className={styles['header']}>Select module</span>
      <form
        className={styles['select-module-container']}
        data-testid="SelectModuleSection"
      >
        <InputField label="Name" labelFor="namelbl" size="large">
          <input
            type="text"
            id="namelbl"
            placeholder="Enter Maven artifact ID for the required module (min 3 characters)"
            data-testid={`Input-name`}
            {...register<IDeployModuleNames>('name', {
              onChange: handleNameChange,
            })}
          />
        </InputField>
        <InputField
          label="Version (optional)"
          labelFor="versionlbl"
          size="large"
        >
          <input
            type="text"
            id="versionlbl"
            placeholder="Enter version number or * for all versions"
            data-testid={`Input-version`}
            {...register<IDeployModuleNames>('version')}
          />
        </InputField>
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'default'}>
            <button
              type="submit"
              data-testid="SearchButton"
              id="submit"
              onClick={(event) => searchModule(event)}
              disabled={isButtonDisabled || loading}
            >
              Search
            </button>
          </ButtonField>
        </ButtonGroupItem>
      </form>
    </CsbErrorBoundary>
  );
}
