import React from 'react';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import {
  ButtonField,
  Icon,
  Modal,
  VerticalLayout,
  VerticalLayoutContent,
  VerticalLayoutFooter,
} from '@wk/components-v3-react16';
import style from './ConfirmModal.module.scss';

interface IConfirmModal {
  isOpen?: boolean;
  onClose?: () => void;
  onConfirm?: () => void;
  content: React.ReactNode;
  confirmLabel?: string;
}

const ConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  content,
  confirmLabel = 'Confirm',
}: IConfirmModal) => {
  const handleConfirm = () => {
    onConfirm?.();
  };

  const handleCancel = () => {
    onClose?.();
  };

  const handleCloseModal = (event: any) => {
    onClose?.();
  };

  return (
    <CsbErrorBoundary>
      <Modal
        isOpen={isOpen}
        srRole="alertdialog"
        srDescribedBy="content-id-002"
        onUserRequest={(event) => handleCloseModal(event)}
      >
        <div className={style['header-buttons']}>
          <ButtonField type="icon" size="large">
            <button
              data-testid="close-icon-button"
              type="button"
              flow-id="close-button"
              onClick={handleCloseModal}
            >
              <Icon name="wk-icon-close"></Icon>
            </button>
          </ButtonField>
        </div>
        <VerticalLayout flowClass={style['vertical-layout']}>
          <VerticalLayoutContent flowClass={style['vertical-layout-content']}>
            <div id="content-id-002">
              <div>
                <h2 className={style['title']}>Confirmation</h2>
                {content}
              </div>
            </div>
          </VerticalLayoutContent>
          <VerticalLayoutFooter>
            <ButtonField type="secondary" size="large">
              <button
                type="button"
                flow-id="confirm-button"
                onClick={handleCancel}
              >
                Cancel{' '}
              </button>
            </ButtonField>
            <ButtonField type="primary" size="large">
              <button
                type="button"
                flow-id="confirm-button"
                onClick={handleConfirm}
              >
                {confirmLabel}
              </button>
            </ButtonField>
          </VerticalLayoutFooter>
        </VerticalLayout>
      </Modal>
    </CsbErrorBoundary>
  );
};

export default ConfirmModal;
