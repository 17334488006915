import { InputField, SelectField } from '@wk/components-react16';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import {
  IWorkflow,
  IWorkflowProduct,
} from 'interfaces/runOrchestration/workflowRow';
import { useEffect, useState } from 'react';
import style from './SetParametersStep.module.scss';
import { UseFormRegister } from 'react-hook-form';
import { AddZeroPrefix } from 'utils/commonFunctions/CommonFunctions';
import { InfoIconWithTooltip } from 'components/CustomIcons/InfoIconWithTooltip/InfoIconWithTooltip';
import { IOrchestrationFullStep } from 'interfaces/orchestration.interface';

export interface ISetParametersStepProps {
  selectedOrchestration: IWorkflow | IWorkflowProduct | null;
  register: UseFormRegister<any>;
}

const SetParametersStep = ({
  selectedOrchestration,
  register,
}: ISetParametersStepProps) => {
  const [dynamicParams, setDynamicParams] = useState<any[]>([]);
  const [uniqueDynamicParams, setUniqueDynamicParams] = useState<any[]>([]);
  const [openIndex, setOpenIndex] = useState<number>(0);

  useEffect(() => {
    const { dynamicParams, uniqueDynamicParams } = checkForDynamicParameters(
      selectedOrchestration?.steps
    );
    setDynamicParams(dynamicParams);
    setUniqueDynamicParams(uniqueDynamicParams);
    setOpenIndex(dynamicParams[0]?.index);
  }, [selectedOrchestration]);

  const handleButtonClick = (index: any) => {
    setOpenIndex(index);
  };

  return (
    <CsbErrorBoundary>
      <div
        data-testid="SetParametersStep"
        className={style['params-container']}
      >
        <section className={style['params-list']}>
          <span className={style['params-list-title']}>
            Define step parameters
          </span>
          {uniqueDynamicParams.map((param: any, index: number) => (
            <button
              onClick={() => handleButtonClick(param.index)}
              key={param.index}
              data-testid={`ParamListButton-${index}`}
              className={
                style[
                  `${
                    param.index === openIndex
                      ? 'params-list-open-btn'
                      : 'params-list-closed-btn'
                  }`
                ]
              }
            >
              <h2 id="expandedByDefault">{`${AddZeroPrefix(param.index)} ${
                param.name
              }`}</h2>
            </button>
          ))}
        </section>
        <div className={style['params-divider']}></div>
        <section className={style['params-values']}>
          {dynamicParams?.length > 0 && (
            <span className={style['step-title']}>{`${AddZeroPrefix(
              dynamicParams.find((p) => p.index === openIndex).index
            )} ${dynamicParams.find((p) => p.index === openIndex).name}`}</span>
          )}
          {dynamicParams.map((param: any, index: number) => (
            <div
              style={{ display: openIndex === param.index ? 'block' : 'none' }}
              key={param.parameter.paramId}
              data-testid={`ParamsValuesSection-${index}`}
            >
              <ParamField param={param.parameter} register={register} />
            </div>
          ))}
        </section>
      </div>
    </CsbErrorBoundary>
  );
};

const ParamField = ({
  param,
  register,
}: {
  param: any;
  register: UseFormRegister<any>;
}) => {
  const haveMultipleValues = param?.parameter?.valueAsArray?.length;
  return (
    <CsbErrorBoundary>
      <div data-testid="ParamField" className={style['param-field']}>
        {haveMultipleValues ? (
          <SelectField label={param?.parameter?.name} labelFor={param?.paramId}>
            <div className={style['param-field-input']}>
              <select
                id={param?.paramId}
                data-testid={`Select-${param?.paramId}`}
                {...register(
                  `parameters.${param?.index}.${param?.parameter?.textId}`
                )}
                defaultValue={''}
              >
                <option value="" disabled hidden>
                  {param.value ?? param?.parameter?.valueAsArray?.[0]}
                </option>
                {param?.parameter?.valueAsArray.map((value: any) => (
                  <option key={value}>{value}</option>
                ))}
              </select>
              {param.configuredExternally && (
                <InfoIconWithTooltip
                  id={param?.paramId}
                  type={null}
                  size={24}
                />
              )}
            </div>
          </SelectField>
        ) : (
          <InputField label={param?.parameter?.name} labelFor={param?.paramId}>
            <div className={style['param-field-input']}>
              <input
                type="text"
                id={param?.paramId}
                placeholder={param.value}
                data-testid={`Input-${param?.paramId}`}
                {...register(
                  `parameters.${param?.index}.${param?.parameter?.textId}`
                )}
              />
              {param.configuredExternally && (
                <InfoIconWithTooltip
                  id={param?.paramId}
                  type={null}
                  size={24}
                />
              )}
            </div>
          </InputField>
        )}
      </div>
    </CsbErrorBoundary>
  );
};

const checkForDynamicParameters = (
  steps: IOrchestrationFullStep[] | undefined
) => {
  const dynamicParams: any[] = [];
  steps?.forEach((step) => {
    step?.paramGroups?.forEach((paramGroup) => {
      paramGroup?.parameters?.forEach((parameter) => {
        if (parameter.isDynamic && parameter.dynamic) {
          dynamicParams.push({
            parameter: { ...parameter, index: step.stepIndex },
            name: step.stepTitle,
            index: step.stepIndex,
          });
        }
      });
    });
  });

  const uniqueDynamicParams = dynamicParams.filter(
    (param, index, self) =>
      self.findIndex((p) => p.name === param.name) === index
  );
  return { dynamicParams, uniqueDynamicParams };
};

export default SetParametersStep;
