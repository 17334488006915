import { useEffect } from 'react';

const useWkTooltipOutsideComponent = (id: string) => {
  const moveWkTooltipToOutsideElement = () => {
    const wkTooltip = document.querySelector(`#${id}`) as HTMLElement;
    const existingFixedTooltip = document.querySelector(`#${id}-temp`);

    // Remove any manual positioning and simply ensure the element exists
    if (wkTooltip && !existingFixedTooltip) {
      // Optional: add logic to ensure the tooltip is removed/adjusted
      wkTooltip.id = `${id}-temp`;
      wkTooltip.style.display = 'block';
      document.body.appendChild(wkTooltip); // Ensure the tooltip is part of the DOM
    }
  };

  useEffect(() => {
    return () => {
      document
        .querySelectorAll(`#${id}-temp`)
        .forEach((element) => element.remove());
    };
  }, [id]);

  return { moveWkTooltipToOutsideElement };
};

export default useWkTooltipOutsideComponent;
