export interface IModuleServer {
  id: number;
  textId: string;
  version: string;
  name: string;
  description: string;
  configId: any;
  isSystem: boolean;
  iconId: any;
  parameters: {
    type: ModuleParameterTypes;
    value: any;
    id: number;
    textId: string;
    name: string;
    required: boolean;
    default: string;
    dependsId: any;
    dependsValue: any;
    dynamicValues: boolean;
  }[];
  instances: {
    updateTime: any;
    hostname: string | null;
    consumersCount: number;
    properties: {
      [key: string]: any;
    };
  }[];
  author: any;
  slave: string;
}

export interface IAvailableModuleServer {
  [tenant: string]: IModuleServer[];
}

export interface IOrchestrationsByModuleServerRequest {
  slave: string;
  moduleTextId: string;
  moduleVersion: string;
}

export interface IOrchestrationsByModuleServer {
  [tenant: string]: string;
}

export interface IModuleRow {
  id: number;
  name: string;
  version: string;
  workers: number;
  consumers: number;
  totalCapacity: number;
  status: 'active' | 'inactive';
  slave: string;
}

export interface IDeployModuleForm {
  name: string;
  version: string;
  selectedModule: string;
  selectedVersion: string;
  selectedServers: string[];
}

export type IDeployModuleNames =
  | 'selectedModule'
  | 'selectedVersion'
  | 'selectedServers'
  | 'name'
  | 'version';

export interface IDeployModuleSearchRow {
  id: any;
  name: string;
  selectedVersion: string;
  type: 'search-results' | 'select-servers';
  allVersions: string[];
  setValue: (name: IDeployModuleNames, value: string) => any;
  register: (name: IDeployModuleNames, value?: string) => any;
  selectionMethod?: () => any;
}

export interface IDeployModuleSearchResponse {
  artifactId: string;
  version: string;
}

export interface IDeployModuleServersResponse {
  [tenant: string]: string[];
}

export interface IDeployModuleServerRow {
  id: any;
  name: string;
  ec2Server: string;
  type: 'search-results' | 'select-servers';
  register: (name: IDeployModuleNames, value?: string) => any;
}

export interface IAvailableModule {
  [tenant: string]: IModuleRow[];
}

export type ModuleParameterTypes =
  | 'ENUM'
  | 'STRING'
  | 'INTEGER'
  | 'BOOLEAN'
  | 'MULTI'
  | 'SECURE'
  | string
  | null;

export interface IModuleParametersRow {
  id: number;
  name: string;
  type: ModuleParameterTypes;
  required: 'Yes' | 'No';
  default: string;
}

export interface IModuleInstancesRow {
  id: number;
  name?: any;
  value?: any;
  tenant?: string;
  section?: 'title-header' | 'properties-header' | 'values';
}

export interface IModuleUsedByRow {
  slave: string;
  moduleId: number;
  name: string;
  id: string;
}

export type ManageModuleTablesTypes = 'module-list' | 'tenant-list';

export enum MODULES_TABS {
  MODULE_LIST = 'module-list',
  TENANT_LIST = 'tenant-list',
}

export interface IModuleParameterItem {
  id: string;
  paramName?: string;
  type?: ModuleParameterTypes;
  paramId?: string;
  value?: string | number | boolean;
  values?: string;
  isRequired?: boolean;
  dependsOn?: string;
  enumValue?: string;
}

export type ICreateModuleOverviewNames =
  | 'moduleName'
  | 'artifactId'
  | 'description'
  | 'authorName'
  | 'email'
  | 'groupId';

export interface ICreateModuleData {
  moduleName: string;
  artifactId: string;
  description: string;
  authorName: string;
  email: string;
  groupId: string;
  parameters: IModuleParameterItem[];
}
