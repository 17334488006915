import { Iconsvg } from '@wk/components-react16/dist/iconsvg/iconsvg';
import style from './OverlayComponent.module.scss';
import { PAGE_OPTIONS } from 'interfaces/table.interface';

export interface IOverlayComponent {
  page?: PAGE_OPTIONS;
  style?: 'fixed' | 'default';
  border?: boolean;
}

const getOverlayText = (page?: PAGE_OPTIONS): string => {
  switch (page) {
    case PAGE_OPTIONS.logs:
      return 'Please wait while your orchestration log data is loaded';
    case PAGE_OPTIONS.orchestrations:
      return 'Please wait while your orchestrations are loading';
    case PAGE_OPTIONS.manageModules:
      return 'Please wait while your modules are loading';
    case PAGE_OPTIONS.commonLoader:
      return 'Please wait while loading';
    case PAGE_OPTIONS.generalPage:
      return '';
    default:
      return 'Please wait while your workflows are loading';
  }
};

export const OverlayComponent = ({
  page,
  style: propStyle,
  border,
}: IOverlayComponent) => {
  const styleOption =
    propStyle === 'default' ? 'csb-overlay' : 'csb-overlay-fixed';
  const overlayText = getOverlayText(page);
  return (
    <div
      className={` ${style[styleOption]} ${
        border ? style['csb-overlay-border'] : ''
      }`}
      data-testid="Overlay"
    >
      <span className={style['csb-overlay-text']}>{overlayText}</span>
      <Iconsvg name="spinner-alt" isSpin isFill size={24}></Iconsvg>
    </div>
  );
};
