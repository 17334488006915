import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const modulesApi = createApi({
  reducerPath: 'modulesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/csb/modules',
    prepareHeaders: (headers) => {
      return headers;
    },
  }),
  endpoints: (builder) => ({
    generateModule: builder.query({
      query: (data: any) => {
        return {
          url: `generate?module=${JSON.stringify(data)}`,
          credentials: 'include',
          responseHandler: async (response) => {
            const contentDisposition =
              response?.headers.get('Content-Disposition') || 'download.zip';
            const contentType = response?.headers.get('Content-Type');
            const blob = await response.blob();

            return new Promise((resolve) => {
              const reader = new FileReader();
              reader.onloadend = function () {
                const base64data = reader.result;
                resolve({
                  data: base64data,
                  filename: contentDisposition
                    .split('filename=')[1]
                    ?.replace(/["']/g, ''),
                  contentType,
                });
              };
              reader.readAsDataURL(blob);
            });
          },
        };
      },
      keepUnusedDataFor: 1,
    }),
  }),
});

export const { useGenerateModuleQuery, useLazyGenerateModuleQuery } =
  modulesApi;
