import { Iconsvg, InputField, TextareaField } from '@wk/components-react16';
import { selectUserRole, selectUserEmail } from 'redux/auth/AuthSlice';
import { useAppSelector } from 'redux/hooks';
import styles from './ServiceNowModal.module.scss';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestrationRow.interface';

interface IServiceNowModal {
  data: IOrchestrationRow;
  register: UseFormRegister<FieldValues>;
}

export default function ServiceNowModal({ data, register }: IServiceNowModal) {
  const userRoleSelector = useAppSelector((state) => selectUserRole(state));
  const userEmailSelector = useAppSelector((state) => selectUserEmail(state));
  const inputsFields = [
    {
      name: 'Orchestration id',
      type: 'number',
      id: 'orchestrationId',
      value: data.id,
    },
    {
      name: 'Orchestration name',
      type: 'text',
      id: 'orchestrationName',
      value: data.name,
    },
    {
      name: 'Username',
      type: 'text',
      id: 'username',
      value: userEmailSelector,
    },
    { name: 'CSB role', type: 'text', id: 'csbRole', value: userRoleSelector },
    {
      name: 'CSB instance url',
      type: 'text',
      id: 'csbInstanceUrl',
      value: window.location.href,
    },
  ];
  return (
    <CsbErrorBoundary>
      <div className={`${styles['service-now-modal']}`}>
        <section className={`${styles['description']}`}>
          <p>
            On 'submit' an email with the following information is sent
            automatically to L2 support, which creates a ServiceNow ticket
          </p>
        </section>
        <section>
          <input
            style={{ display: 'none' }}
            {...register('action')}
            value={'serviceNow'}
          ></input>
          {inputsFields.map((field) => (
            <InputField
              label={field.name}
              labelFor={field.id}
              size="small"
              key={field.id}
            >
              <input
                type={field.type}
                id={field.id}
                readOnly
                value={field.value}
                data-testid={`Input-${field.id}`}
                {...register(field.id)}
              />
            </InputField>
          ))}
          <div
            data-testid="ErrorLogFile"
            id="errorLogFile"
            className={`${styles['file-button']}`}
          >
            <Iconsvg
              name="link"
              title="Error log file name"
              color="#232323"
              size={16}
            ></Iconsvg>
            <span>{`csb_logfile_${data?.name}_${data?.id}.txt`}</span>
          </div>
          <TextareaField
            label="Additional information"
            labelFor="additionalInformation"
            size="small"
            key="additionalInformation"
          >
            <textarea
              id="additionalInformation"
              placeholder="Add any additional information"
              data-testid={'TextArea-additionalInformation'}
              {...register('additionalInformation')}
            />
          </TextareaField>
        </section>
      </div>
    </CsbErrorBoundary>
  );
}
