import { Notification, Typography } from '@wk/components-v3-react16';
import style from './NotificationBox.module.scss';
import { ButtonField } from '@wk/components-react16';
import { NOTIFICATION_TYPES } from 'utils/common-constants';

export type NotificationContent = {
  type: NOTIFICATION_TYPES;
  header: string;
  textContent: string;
  ctaText?: string;
};

interface INotificationBox {
  notificationContent: NotificationContent;
  onCta: () => void;
}

const NotificationBox = ({ notificationContent, onCta }: INotificationBox) => {
  const { type, header, textContent, ctaText } = notificationContent;
  return (
    <div className={style['notification-box']} data-testid="NotificationBox">
      <div className={`notification-story8 ${style['container']}`}>
        <Notification type={type}>
          <div slot="notificationHeading">{header}</div>
          <span slot="notificationContent">
            <Typography>
              <p>{textContent}</p>
              {ctaText && (
                <ButtonField>
                  <button
                    className={style['cta']}
                    type="button"
                    onClick={onCta}
                  >
                    {ctaText}
                  </button>
                </ButtonField>
              )}
            </Typography>
          </span>
        </Notification>
      </div>
    </div>
  );
};

export default NotificationBox;
