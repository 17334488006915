import {
  StepItem,
  Stepthrough,
  WizardStepthrough,
} from '@wk/components-react16';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  selectSelectedWorkflow,
  selectWizardActiveIndex,
} from 'redux/runOrchestrations/RunOrchestrationsSlice';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import React, { useEffect, useState } from 'react';
import style from './ConfigureAndRunModal.module.scss';
import UploadFilesStep from './components/UploadFilesStep/UploadFilesStep';
import {
  selectAdditionalData,
  setAdditionalData,
} from 'redux/modal/ModalSlice';
import SetParametersStep from './components/SetParametersStep/SetParametersStep';
import SetPriorityStep from './components/SetPriorityStep/SetPriorityStep';
import {
  FieldErrorsImpl,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { IWorkflowProduct } from 'interfaces/runOrchestration/workflowRow';

export enum WIZARD_STEPS {
  SELECT_FILES = 'Select files',
  SET_PRIORITY = 'Set priority',
  SET_PARAMETERS = 'Set parameters',
}

export interface IConfigureAndRunModal {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}

const ConfigureAndRunModal = ({
  register,
  setValue,
  setError,
  clearErrors,
  errors,
}: IConfigureAndRunModal) => {
  const selectedOrchestration = useAppSelector(selectSelectedWorkflow);
  const wizardActiveindex = useAppSelector(selectWizardActiveIndex);
  const dispatch = useAppDispatch();
  const modalAdditionalData = useAppSelector(selectAdditionalData);
  const [wizardItems, setWizardItems] = useState<WIZARD_STEPS[] | undefined>(
    undefined
  );
  const STEP_HEADER = 'step-header';
  const STEP_CONTENT_CONTAINER = 'step-content-container';

  useEffect(() => {
    register('name');
    setValue('name', selectedOrchestration?.name);
    if ((selectedOrchestration as IWorkflowProduct)?.parentName) {
      register('productName', (selectedOrchestration as any)?.name);
      setValue(
        'productName',
        (selectedOrchestration as IWorkflowProduct)?.name
      );
      setValue('name', (selectedOrchestration as any)?.parentName);
    }
    if (
      !selectedOrchestration?.supportsPrioritization &&
      selectedOrchestration?.hasDynamicParameters === true
    ) {
      setWizardItems([WIZARD_STEPS.SELECT_FILES, WIZARD_STEPS.SET_PARAMETERS]);
    } else if (
      selectedOrchestration?.supportsPrioritization === true &&
      !selectedOrchestration?.hasDynamicParameters
    ) {
      setWizardItems([WIZARD_STEPS.SELECT_FILES, WIZARD_STEPS.SET_PRIORITY]);
    } else if (
      !selectedOrchestration?.hasDynamicParameters &&
      !selectedOrchestration?.supportsPrioritization
    ) {
      setWizardItems([]);
    } else {
      setWizardItems([
        WIZARD_STEPS.SELECT_FILES,
        WIZARD_STEPS.SET_PRIORITY,
        WIZARD_STEPS.SET_PARAMETERS,
      ]);
    }
  }, [selectedOrchestration]);

  useEffect(() => {
    const title = document.querySelector('[data-e2e="cg-modal-title"]');
    if (title) {
      if (wizardItems && wizardItems.length > 0) {
        title.textContent = 'Select files and configure orchestration';
      } else {
        title.textContent = 'Select files';
      }
      dispatch(setAdditionalData({ ...modalAdditionalData, wizardItems }));
    }
  }, [wizardItems]);

  return (
    <CsbErrorBoundary>
      {wizardItems && (
        <>
          {wizardItems.length > 0 && (
            <div className={style['wizard-container']}>
              <WizardStepthrough>
                <div
                  slot="xs"
                  style={{ padding: '2rem 0.5rem 1rem 0' }}
                  data-testid="Wizard-xs"
                >
                  <Stepthrough mode="compact" activeIndex={wizardActiveindex}>
                    {wizardItems?.map((step, i) => {
                      return (
                        <React.Fragment key={'cogen-20411' + i.toString()}>
                          <StepItem slot="stepItem">
                            <span
                              className={
                                i === 0 ? style['step-0'] : style['step']
                              }
                              data-testid={`StepItem-${i}`}
                            >
                              {step}
                            </span>
                          </StepItem>
                        </React.Fragment>
                      );
                    })}
                  </Stepthrough>
                </div>
                <div slot="default" data-testid="Wizard-default">
                  <Stepthrough mode="normal" activeIndex={wizardActiveindex}>
                    {wizardItems?.map((step, i) => {
                      return (
                        <React.Fragment key={'cogen-20416' + i.toString()}>
                          <StepItem slot="stepItem">
                            <span
                              className={
                                i === 0 ? style['step-0'] : style['step']
                              }
                              data-testid={`StepItem-${i}`}
                            >
                              {step}
                            </span>
                          </StepItem>
                        </React.Fragment>
                      );
                    })}
                  </Stepthrough>
                </div>
              </WizardStepthrough>
            </div>
          )}
          <div
            className={style['configure-and-run-modal-container']}
            data-testid="ConfigureAndRunModal"
          >
            <div
              className={
                style[`${wizardActiveindex === 0 ? 'display' : 'hide'}`]
              }
            >
              <span className={style[STEP_HEADER]}>Select files</span>
              <div className={`${style['upload-step']}`} data-testid="Step-0">
                <UploadFilesStep register={register} setValue={setValue} />
              </div>
            </div>
            <div
              className={
                style[
                  `${
                    (wizardActiveindex === 1 && wizardItems.length === 3) ||
                    (wizardActiveindex === 1 &&
                      selectedOrchestration?.supportsPrioritization)
                      ? 'display'
                      : 'hide'
                  }`
                ]
              }
            >
              <span className={style[STEP_HEADER]}>Orchestration priority</span>
              <span className={style['step-subtitle']}>
                Enter a priority value to determine the sequence in which
                pending jobs are run to fulfil a Service Level Agreement (SLA).
                1 = more important, 10 = less important.
              </span>
              <div
                data-testid="Step-1"
                className={` ${style[STEP_CONTENT_CONTAINER]} ${style['priority-step']}`}
              >
                <SetPriorityStep
                  register={register}
                  setError={setError}
                  clearErrors={clearErrors}
                  errors={errors}
                />
              </div>
            </div>
            <div
              className={
                style[
                  `${
                    wizardActiveindex === wizardItems.length - 1 &&
                    selectedOrchestration?.hasDynamicParameters === true
                      ? 'display'
                      : 'hide'
                  }`
                ]
              }
            >
              <span className={style[STEP_HEADER]}>Set parameters</span>
              <span className={style['step-subtitle']}>
                Update the default parameters for each orchestration step, if
                needed, or accept them by clicking 'Submit'.
              </span>
              <div
                data-testid="Step-2"
                className={`${style[STEP_CONTENT_CONTAINER]} ${style['parameters-step']}`}
              >
                <SetParametersStep
                  selectedOrchestration={selectedOrchestration}
                  register={register}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </CsbErrorBoundary>
  );
};

export default ConfigureAndRunModal;
