import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAvailableModuleServer,
  IModuleServer,
  ManageModuleTablesTypes,
  MODULES_TABS,
} from 'interfaces/modules/module.interface';
import {
  fetchAvailableModules,
  fetchAvailableModulesWithoutLoading,
} from 'api/manageModules/manageModulesThunks';
import { isObjectsAreEqualDeep } from 'utils/commonFunctions/CommonFunctions';

export interface IManageModulesState {
  selectedModule: IModuleServer | null;
  selectedModuleInTenantTable: IModuleServer | null;
  availableModules: IAvailableModuleServer | {};
  loading: boolean;
  currentTab: ManageModuleTablesTypes;
}

export const SLICE_KEY = 'manageModules';

const initialState: IManageModulesState = {
  selectedModule: null,
  selectedModuleInTenantTable: null,
  availableModules: [],
  loading: false,
  currentTab: MODULES_TABS.MODULE_LIST,
};

const sortByTenantAndRemoveInvalidModules = (
  payload: IAvailableModuleServer
): IAvailableModuleServer => {
  const updatedPayload = Object.entries(payload).map(([key, modules]) => {
    const validModules = modules.filter(
      (module) => module.id !== null && module.name && module.name.trim() !== ''
    );
    return [key, validModules];
  });

  updatedPayload.sort(([a], [b]) => (a as string).localeCompare(b as string));

  return Object.fromEntries(updatedPayload);
};

const managaModulesSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setSelectedModule: (
      state,
      {
        payload,
      }: PayloadAction<{
        table: ManageModuleTablesTypes;
        module: IModuleServer | null;
      }>
    ) => {
      if (payload.table === MODULES_TABS.MODULE_LIST) {
        return {
          ...state,
          selectedModule: payload.module,
        };
      }
      return {
        ...state,
        selectedModuleInTenantTable: payload.module,
      };
    },
    clearState: (state) => {
      return {
        selectedModule: null,
        selectedModuleInTenantTable: null,
        availableModules: [],
        loading: false,
        currentTab: MODULES_TABS.MODULE_LIST,
      };
    },
    setSelectedModuleFromRow: (
      state,
      {
        payload,
      }: PayloadAction<{
        table: ManageModuleTablesTypes;
        id: number;
        slave: string;
      } | null>
    ) => {
      if (payload) {
        const moduleArray: IModuleServer[] = (
          state.availableModules as IAvailableModuleServer
        )[payload.slave]
          ? Array.from(
              (
                state.availableModules as {
                  [key: string]: IModuleServer[];
                }
              )[payload.slave]
            )
          : [];
        const foundModule = moduleArray.find((x) => x.id === payload.id);
        if (payload?.table === MODULES_TABS.MODULE_LIST) {
          return {
            ...state,
            selectedModule: foundModule ?? null,
          };
        }
        return {
          ...state,
          selectedModuleInTenantTable: foundModule ?? null,
        };
      }
      return {
        ...state,
        selectedModule: state.selectedModule,
        selectedModuleInTenantTable: state.selectedModuleInTenantTable,
      };
    },
    setCurrentTab: (
      state,
      { payload }: PayloadAction<ManageModuleTablesTypes>
    ) => {
      return {
        ...state,
        currentTab: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAvailableModules.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    });
    builder.addCase(fetchAvailableModules.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    });
    builder.addCase(fetchAvailableModules.fulfilled, (state, { payload }) => {
      const sortedPayload = sortByTenantAndRemoveInvalidModules(payload);
      return {
        ...state,
        availableModules: sortedPayload,
        loading: false,
      };
    });
    builder.addCase(
      fetchAvailableModulesWithoutLoading.fulfilled,
      (state, { payload }) => {
        const sortedPayload = sortByTenantAndRemoveInvalidModules(payload);
        const selectedModule =
          state.currentTab === MODULES_TABS.MODULE_LIST
            ? state.selectedModule
            : state.selectedModuleInTenantTable;

        const updatedSelectedModule = selectedModule
          ? sortedPayload[selectedModule.slave]?.find(
              (x) => x.id === selectedModule.id
            ) ?? null
          : null;

        const isSelectedModuleChanged = selectedModule
          ? !isObjectsAreEqualDeep(selectedModule, updatedSelectedModule)
          : false;

        return {
          ...state,
          availableModules: sortedPayload,
          selectedModule:
            isSelectedModuleChanged &&
            state.currentTab === MODULES_TABS.MODULE_LIST
              ? updatedSelectedModule
              : state.selectedModule,
          selectedModuleInTenantTable:
            isSelectedModuleChanged &&
            state.currentTab === MODULES_TABS.TENANT_LIST
              ? updatedSelectedModule
              : state.selectedModuleInTenantTable,
          loading: false,
        };
      }
    );
    builder.addCase(
      fetchAvailableModulesWithoutLoading.rejected,
      (state, action) => {
        return {
          ...state,
          loading: false,
        };
      }
    );
  },
});
export const selectSelectedModule = (state: any) =>
  state.manageModules.selectedModule;

export const selectSelectedModuleInTenantTable = (state: any) =>
  state.manageModules.selectedModuleInTenantTable;

export const selectAvailableModules = (state: any) =>
  state.manageModules.availableModules;

export const selectCurrentTab = (state: any) => state.manageModules.currentTab;

export const selectModulesLoading = (state: any) => state.manageModules.loading;

export const {
  setSelectedModule,
  setSelectedModuleFromRow,
  clearState,
  setCurrentTab,
} = managaModulesSlice.actions;

export default managaModulesSlice.reducer;
