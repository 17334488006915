import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModuleParameterItem } from 'interfaces/modules/module.interface';

export interface ICreateModuleState {
  moduleParameters: IModuleParameterItem[];
  selectedParameter: IModuleParameterItem | null;
}

export const SLICE_KEY = 'createModule';

const initialState: ICreateModuleState = {
  moduleParameters: [],
  selectedParameter: null,
};

const createModuleSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    clearState: () => {
      return {
        moduleParameters: [],
        selectedParameter: null,
      };
    },
    addParameter: (state, action: PayloadAction<IModuleParameterItem>) => {
      return {
        ...state,
        moduleParameters: [...state.moduleParameters, action.payload],
        selectedParameter: action.payload,
      };
    },
    selectParameter: (state, action: PayloadAction<IModuleParameterItem>) => {
      return {
        ...state,
        selectedParameter: action.payload,
      };
    },
    setParameterList: (
      state,
      action: PayloadAction<IModuleParameterItem[]>
    ) => {
      return {
        ...state,
        moduleParameters: action.payload,
      };
    },
    updateParameterProps: (
      state,
      action: PayloadAction<{ id: string; newSelected: IModuleParameterItem }>
    ) => {
      return {
        ...state,
        moduleParameters: state.moduleParameters.map((item) => {
          if (item.id === action.payload.id) {
            return { ...action.payload.newSelected };
          }
          return item;
        }),
        selectedParameter: {
          ...action.payload.newSelected,
        },
      };
    },
    deleteParameter: (
      state,
      action: PayloadAction<{ id: string; param: IModuleParameterItem }>
    ) => {
      const paramIndex = state.moduleParameters.findIndex(
        (item) => item.id === action.payload.id
      );
      const nextSelectedParamIndex =
        paramIndex === 0 ? paramIndex + 1 : paramIndex - 1;
      const paramList = state.moduleParameters.map((item) => {
        if (
          action.payload.param?.paramId &&
          action.payload.param.paramId === item.dependsOn
        ) {
          return { ...item, dependsOn: '', enumValue: '' };
        } else {
          return { ...item };
        }
      });

      return {
        ...state,
        moduleParameters: paramList.filter(
          (filterItem) => filterItem.id !== action.payload.id
        ),
        selectedParameter: paramList?.[nextSelectedParamIndex] || null,
      };
    },
  },
});

export const selectSelectedParameter = (state: any) =>
  state.createModule.selectedParameter;

export const selectParameterList = (state: any) =>
  state.createModule.moduleParameters;

export const {
  clearState,
  addParameter,
  setParameterList,
  updateParameterProps,
  selectParameter,
  deleteParameter,
} = createModuleSlice.actions;

export default createModuleSlice.reducer;
