import { Iconsvg, Tooltip, TooltipBody } from '@wk/components-react16';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { ILogRow } from 'interfaces/logRow.interface';
import { Useuid } from 'utils/hooks/useUid';
import { generateSeverityStatusIcon } from '../../utils/common-constants';

export interface IErrorLogCellStatusProps {
  rowData: ILogRow;
}

const ErrorLogCellStatus = (props: any) => {
  const rowData: ILogRow = props.data;
  const uniqueId = Useuid();
  const severityMap = {
    ERROR: 'Error',
    WARN: 'Warning',
    DEBUG: 'Debug',
    INFO: 'Information',
  };

  return (
    <CsbErrorBoundary>
      <div
        data-testid="ErrorLogCellStatus"
        id={`error-log-cell-status-${uniqueId}`}
      >
        <div style={{ pointerEvents: 'none' }}>
          <Iconsvg
            name={generateSeverityStatusIcon(rowData?.severity)?.name}
            title={generateSeverityStatusIcon(rowData?.severity)?.description}
            color={
              generateSeverityStatusIcon(rowData?.severity)?.color || 'black'
            }
            size={24}
          ></Iconsvg>
        </div>
        {rowData?.severity && (
          <div>
            <Tooltip
              position="left"
              targetSelector={`#error-log-cell-status-${uniqueId}`}
              id="error-log-cell-status-tooltip"
            >
              <TooltipBody slot="tooltipBody">
                <span>{severityMap[rowData?.severity]}</span>
              </TooltipBody>
            </Tooltip>
          </div>
        )}
      </div>
    </CsbErrorBoundary>
  );
};

export default ErrorLogCellStatus;
