import { NotificationContainer } from '@wk/components-react16';
import { useAppSelector } from 'redux/hooks';
import { selectNotifications } from 'redux/toast/toastSlice';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { IToast, ToastTypes } from 'interfaces/toasts';
import { ServiceNowToast } from './ServiceNowToast/ServiceNowToast';
import { ConfigureAndRunToast } from './ConfigureAndRunToast/ConfigureAndRunToast';
import { InformationToast } from './InformationToast/InformationToast';

export const ToastContainer = () => {
  const notifications: IToast[] = useAppSelector(selectNotifications);

  const toastGenerator = (toast: IToast) => {
    const toastsTypes: Record<ToastTypes, JSX.Element> = {
      serviceNow: <ServiceNowToast item={toast} key={toast.id} />,
      configureAndRunModal: (
        <ConfigureAndRunToast item={toast} key={toast.id} />
      ),
      informationToast: <InformationToast item={toast} key={toast.id} />,
    };
    return toastsTypes[toast.type];
  };

  return (
    <CsbErrorBoundary>
      <>
        {notifications?.length > 0 && (
          <NotificationContainer>
            {notifications?.map((item: IToast) => toastGenerator(item))}
          </NotificationContainer>
        )}
      </>
    </CsbErrorBoundary>
  );
};
