import LogModal from 'components/Modals/body/LogModal/LogModal';
import ServiceNowModal from 'components/Modals/body/ServiceNowModal/ServiceNowModal';
import ConfigureAndRunModal from 'components/Modals/body/ConfigureAndRunModal/ConfigureAndRunModal';
import CreateProductModal from 'components/Modals/body/CreateProductModal/CreateProductModal';
import DeployModuleModal from 'components/Modals/body/DeployModuleModal/DeployModuleModal';

import CancelResetApplyFooter from 'components/Modals/footers/CancelResetApplyFooter/CancelResetApplyFooter';
import CancelDownloadFooter from 'components/Modals/footers/CancelDownloadFooter/CancelDownloadFooter';
import CancelSubmitFooter from 'components/Modals/footers/CancelSubmitFooter/CancelSubmitFooter';
import CancelNextSubmitFooter from 'components/Modals/footers/CancelNextSubmit/CancelNextSubmitFooter';
import CancelDeployFooter from 'components/Modals/footers/CancelDeployFooter/CancelDeployFooter';
import CreateModuleModal from './body/CreateModuleModal/CreateModuleModal';
import { ManageModuleButtonsModal } from './body/ManageModuleButtonsModal/ManageModuleButtonsModal';
import { ManageModuleButtonsFooter } from './footers/ManageModuleButtonsFooter/ManageModuleButtonsFooter';
import { RunOrchestrationOptionsModal } from './body/RunOrchestrationOptionsModal/RunOrchestrationOptionsModal';
import { RunOrchestrationOptionFooter } from './footers/RunOrchestrationOptionFooter/RunOrchestrationOptionFooter';
import CancelCreateModuleFooter from './footers/CancelCreateModuleFooter/CancelCreateModuleFooter';

export enum MODAL_NAMES {
  LOG_MODAL = 'modals/logModal',
  SERVICE_NOW_MODAL = 'modals/serviceNowModal',
  CONFIGURE_AND_RUN = 'modals/configureAndRun',
  CREATE_PRODUCT = 'modals/createProductModal',
  EMPTY = '',
  EDIT_PRODUCT = 'modals/editProductModal',
  DEPLOY_MODULE = 'modals/deployModuleModal',
  MANAGE_MODULE_BUTTONS = 'modals/manageModuleButtonsModal',
  RUN_ORCHESTRATION_OPTIONS_BUTTONS = 'modals/runOrchestrationOptionModal',
  CREATE_MODULE = 'modals/createModuleModal',
}

export const MODAL_FORMS_LIST: {
  [key in MODAL_NAMES]?: any;
} = {
  [MODAL_NAMES.LOG_MODAL]: LogModal,
  [MODAL_NAMES.SERVICE_NOW_MODAL]: ServiceNowModal,
  [MODAL_NAMES.CONFIGURE_AND_RUN]: ConfigureAndRunModal,
  [MODAL_NAMES.CREATE_PRODUCT]: CreateProductModal,
  [MODAL_NAMES.EDIT_PRODUCT]: CreateProductModal,
  [MODAL_NAMES.DEPLOY_MODULE]: DeployModuleModal,
  [MODAL_NAMES.MANAGE_MODULE_BUTTONS]: ManageModuleButtonsModal,
  [MODAL_NAMES.RUN_ORCHESTRATION_OPTIONS_BUTTONS]: RunOrchestrationOptionsModal,
  [MODAL_NAMES.CREATE_MODULE]: CreateModuleModal,
};

export enum MODAL_FOOTER_NAMES {
  CANCEL_RESET_APPLY = 'cancel-reset-apply',
  CANCEL_DOWNLOAD = 'cancel-download',
  CANCEL_SUBMIT = 'cancel-submit',
  CANCEL_NEXT = 'cancel-next',
  CANCEL_DEPLOY = 'cancel-deploy',
  CANCEL_CREATE_MODULE = 'cancel-create-module',
  MANAGE_MODULE_BUTTONS = 'manage-module-buttons',
  RUN_ORCHESTRATION_OPTIONS_BUTTONS = 'run-orchestration-options-buttons',
  EMPTY = '',
}

export const MODAL_FOOTER_LIST: { [key in MODAL_FOOTER_NAMES]?: any } = {
  [MODAL_FOOTER_NAMES.CANCEL_RESET_APPLY]: CancelResetApplyFooter,
  [MODAL_FOOTER_NAMES.CANCEL_DOWNLOAD]: CancelDownloadFooter,
  [MODAL_FOOTER_NAMES.CANCEL_SUBMIT]: CancelSubmitFooter,
  [MODAL_FOOTER_NAMES.CANCEL_NEXT]: CancelNextSubmitFooter,
  [MODAL_FOOTER_NAMES.CANCEL_DEPLOY]: CancelDeployFooter,
  [MODAL_FOOTER_NAMES.MANAGE_MODULE_BUTTONS]: ManageModuleButtonsFooter,
  [MODAL_FOOTER_NAMES.RUN_ORCHESTRATION_OPTIONS_BUTTONS]:
    RunOrchestrationOptionFooter,
  [MODAL_FOOTER_NAMES.CANCEL_CREATE_MODULE]: CancelCreateModuleFooter,
};
