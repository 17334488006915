import { useState, useEffect } from 'react';

const useDynamicHeight = () => {
  const [heightOffset, setHeightOffset] = useState(200);

  useEffect(() => {
    const updateHeightOffset = () => {
      const screenHeight = window.innerHeight;
      if (screenHeight < 470) {
        setHeightOffset(280);
      } else if (screenHeight < 730) {
        setHeightOffset(250);
      } else {
        setHeightOffset(200);
      }
    };

    updateHeightOffset();
    window.addEventListener('resize', updateHeightOffset);

    return () => {
      window.removeEventListener('resize', updateHeightOffset);
    };
  }, []);

  return heightOffset;
};

export default useDynamicHeight;
