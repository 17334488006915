import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './RunOrchestrationOptionsModal.module.scss';

export interface IRunOrchestrationOptionsModal {
  id: number;
  productId?: number;
}

const actionTexts = {
  removeOrchestration: `Are you sure you want to delete this orchestration?\n\n All pending orchestration instances associated with this orchestration will also be deleted`,
  removeProduct: `Are you sure you want to delete this product variant?\n\n All pending orchestration instances associated with this product variant will also be deleted`,
};

export const RunOrchestrationOptionsModal = ({
  productId,
}: IRunOrchestrationOptionsModal) => {
  const actionText = productId
    ? actionTexts.removeProduct
    : actionTexts.removeOrchestration;

  return (
    <CsbErrorBoundary>
      <div data-testid="RunOrchestrationOptionsModal">
        <span className={style['text']}>{actionText}</span>
      </div>
    </CsbErrorBoundary>
  );
};
