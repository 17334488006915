import { usePostLogoutMutation } from 'api/auth/authApi';
import { useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAppSelector } from './redux/hooks';
import { RootState } from './redux/store';
import HeaderCsb from './components/HeaderCsb/HeaderCsb';
import SpineMenuCsb from './components/SpineMenuCsb/SpineMenuCsb';

import {
  currentClearTimeout,
  currentSetTimeout,
} from 'utils/commonFunctions/CommonFunctions';
import { useServerSideEvents } from 'utils/hooks/useSSE';
import RunOrchestrationsLazy from 'pages/RunOrchestration/RunOrchestrations.lazy';
import OrchestrationDetailsLazy from 'pages/OrchestrationDetails/OrchestrationDetails.lazy';
import DashboardLazy from 'pages/Dashboard/Dashboard.lazy';
import ManageModulesLazy from 'pages/ManageModules/ManageModules.lazy';
import { WkIconDefaultSprite } from '@wk/components-v3-react16';
import NotificationBox from 'components/NotificationBox/NotificationBox';
import {
  NOTIFICATION_BOX_CONTENT,
  NOTIFICATION_BOX_MESSAGES,
} from 'utils/common-constants';
import Config from './config.json';

import { useLogin } from 'utils/hooks/auth/useLogin';

const EVENTS_FOR_LOGOUT = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress',
];

const DEFAULT_TIMEOUT = 60 * 15 * 1000;

function App(): JSX.Element {
  const isLoggedIn = useAppSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const authError = localStorage.getItem('authError') as
    | NOTIFICATION_BOX_MESSAGES
    | '';
  const logoutTimer: any = useRef(null);

  const [logout] = usePostLogoutMutation();
  useServerSideEvents();
  const { isLoading } = useLogin();

  const handleLogout = () => {
    localStorage.setItem('authError', '');

    window.location.href = `${Config.CSB_OAUTH_ONE_ID_DOMAIN}/idp/startSLO.ping?TargetResource=${window.location.origin}/dashboard&InErrorResource=${window.location.origin}/dashboard`;
  };

  // Effect for auto logout
  useEffect(() => {
    const resetTimeout = (e: any) => {
      currentClearTimeout(logoutTimer);
      currentSetTimeout(
        logoutTimer,
        () => {
          logout(null);
        },
        DEFAULT_TIMEOUT
      );
    };

    for (const event of EVENTS_FOR_LOGOUT) {
      window.addEventListener(event, resetTimeout);
    }

    return () => {
      for (const event of EVENTS_FOR_LOGOUT) {
        window.removeEventListener(event, resetTimeout);
      }
    };
  }, []);

  return (
    <div>
      <HeaderCsb />
      <WkIconDefaultSprite />
      {isLoggedIn ? (
        <div className="App">
          <BrowserRouter>
            <SpineMenuCsb>
              <Routes>
                <Route
                  path="/dashboard/orchestration/:id"
                  element={<OrchestrationDetailsLazy />}
                />
                <Route path="/dashboard" element={<DashboardLazy />} />
                <Route
                  path="/run-orchestrations"
                  element={<RunOrchestrationsLazy />}
                />
                <Route path="/manage-modules" element={<ManageModulesLazy />} />
              </Routes>
            </SpineMenuCsb>
          </BrowserRouter>
        </div>
      ) : (
        authError &&
        NOTIFICATION_BOX_CONTENT[authError] &&
        !isLoading && (
          <NotificationBox
            notificationContent={NOTIFICATION_BOX_CONTENT[authError]}
            onCta={handleLogout}
          />
        )
      )}
    </div>
  );
}

export default App;
