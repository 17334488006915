import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-xml';
import useDynamicHeight from '../../hooks/useDynamicHeight';
import { useEffect, useRef } from 'react';

interface IPomSourceCode {}

const PomSourceCode = ({}: IPomSourceCode) => {
  const heightOffset = useDynamicHeight();
  const editorRef = useRef<AceEditor | null>(null);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current!.editor.resize();
    }
  }, [heightOffset]);

  const xmlCode = ``;

  return (
    <CsbErrorBoundary>
      <div data-testid="PomSourceCode">
        <AceEditor
          mode="xml"
          name="pom_editor"
          width="100%"
          height={`calc(80vh - ${heightOffset}px)`}
          value={xmlCode}
          showPrintMargin={false}
          editorProps={{ $blockScrolling: true }}
          setOptions={{ useWorker: false, wrap: true }}
        />
      </div>
    </CsbErrorBoundary>
  );
};

export default PomSourceCode;
