import { ORCHESTRATION_STATUSES, STEP_STATUSES } from 'utils/common-constants';
import { IUpdateOrchestrationStatus } from 'interfaces/dashboard/dashboardSlice.interface';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestrationRow.interface';
import {
  calculateJobDurationIfApplies,
  combineStatus,
  getNameFromEmail,
  updateSteps,
} from 'utils/commonFunctions/CommonFunctions';
import { DateUtils } from 'utils/dateUtils/DateUtils';
import {
  IOrchestrationServer,
  IStepInformation,
} from 'interfaces/orchestration.interface';

export const mapResponseJobsToTableData = (
  jobs: IOrchestrationServer[]
): IOrchestrationRow[] => jobs.map((job) => mapJobToTableData(job));

export const mapJobToTableData = (
  job: IOrchestrationServer
): IOrchestrationRow => {
  return {
    id: job.jobId,
    name: job.workflow?.name || '',
    originalFileName: job.originalFileName || '',
    submitTime: DateUtils.formatDateFromUtcString(job.submitTime as string),
    originalStartTime: (job.startTime ?? job.submitTime) as string,
    originalEndTime: job.endTime as string,
    startTime: job.startTime
      ? DateUtils.formatDateFromUtcString(job.startTime as string)
      : '',
    endTime:
      job.status !== ORCHESTRATION_STATUSES.PENDING
        ? DateUtils.formatDateFromUtcString(job.endTime as string)
        : '',
    duration: calculateJobDurationIfApplies(job),
    originalStatus: job.status,
    status: job.status
      ? combineStatus(job.status)
      : ORCHESTRATION_STATUSES.PENDING,
    originalUser: job.user,
    user: {
      name: getNameFromEmail(job.user?.email) ?? 'Automated process',
      id: job.user?.id,
      email: job.user?.email,
    },
    supportResubmit: supportResubmit(job),
    step: calculateStep(job),
    steps: job.steps,
    stepsCount: job.stepsCount,
    workflowId: job.workflow?.workflowId,
    product: job.product ?? undefined,
  };
};

export const updateJobStatusInTable = (
  job: IOrchestrationRow,
  updatedData: IUpdateOrchestrationStatus
): IOrchestrationRow => {
  const partialUpdatedJob = {
    ...job,
    status: combineStatus(updatedData.status),
    startTime: updatedData.startTime
      ? DateUtils.formatDateFromUtcString(updatedData.startTime)
      : job.startTime,
    endTime: updatedData.endTime
      ? DateUtils.formatDateFromUtcString(updatedData.endTime)
      : job.endTime,
    supportResubmit: supportResubmit(job),
  };
  return {
    ...partialUpdatedJob,
    duration: calculateJobDurationIfApplies(partialUpdatedJob),
    step: calculateStep(partialUpdatedJob),
  };
};

export const updateJobStepsInTable = (
  job: IOrchestrationRow,
  updatedData: IStepInformation
) => {
  const updatedJob = updateSteps(job, updatedData);
  let updatedSteps = updatedJob.steps || [];
  if (updatedSteps?.length) {
    if (
      !updatedSteps.some((step) => step.jobStepId === updatedData.jobStepId)
    ) {
      updatedSteps.push(updatedData);
    }
  } else {
    updatedSteps = [updatedData];
  }

  return {
    ...updatedJob,
    step: calculateStep(
      { ...updatedJob, steps: updatedSteps },
      updatedData.jobStepId
    ),
    steps: updatedSteps,
  };
};

const supportResubmit = (job: any | IOrchestrationRow): boolean => {
  const statuses = [
    ORCHESTRATION_STATUSES.FAILED,
    ORCHESTRATION_STATUSES.FINISHED_ERROR,
  ];

  return (
    statuses.includes(job.status) &&
    ((job.workflow?.supportResubmit && job.masterJobId != null) ||
      job.supportResubmit === true)
  );
};

const calculateStep = (job: any, currentStep?: number): string => {
  if (job.status === ORCHESTRATION_STATUSES.PENDING) {
    return '';
  } else if (job.status === ORCHESTRATION_STATUSES.STOPPING) {
    return 'Stopping';
  } else if (job.status === ORCHESTRATION_STATUSES.STOPPED) {
    return 'Stopped';
  } else if (job.status === ORCHESTRATION_STATUSES.FINISHED) {
    return 'Finished';
  } else if (job.status === ORCHESTRATION_STATUSES.FINISHED_ERROR) {
    return 'Finished with errors';
  } else if (job.status === ORCHESTRATION_STATUSES.FAILED) {
    return 'Failed';
  } else {
    return calculateStepNumberIfInProgress(job);
  }
};

export const calculateStepNumberIfInProgress = (
  job: IOrchestrationServer | IOrchestrationRow
): string => {
  const { steps, stepsCount } = job;

  if (
    !steps ||
    steps.length === 0 ||
    steps[0]?.status === STEP_STATUSES.NOT_STARTED
  ) {
    return `Step 1 of ${stepsCount}`;
  }

  const lastStepIndex = steps[steps.length - 1]?.index;
  const inProgressStepsIndices = steps
    .filter((step) => step.status === STEP_STATUSES.IN_PROGRESS)
    .map((step) => step.index);

  if (inProgressStepsIndices.length > 0) {
    const stepText = inProgressStepsIndices.length === 1 ? 'Step' : 'Steps';
    return `${stepText} ${inProgressStepsIndices.join(', ')} of ${stepsCount}`;
  }
  return lastStepIndex
    ? `Step ${lastStepIndex} of ${stepsCount}`
    : (job as IOrchestrationRow).step ?? 'Loading...';
};
