import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  IOrchestrationsByModuleServer,
  IOrchestrationsByModuleServerRequest,
} from 'interfaces/modules/module.interface';
import { IServerWorkflow } from 'interfaces/workflow.interface';
import { handleThunkError } from 'utils/thunkErrorHandler';

const BASE_URL = '/csb/workflows';

export const fetchWorkflows = createAsyncThunk(
  'runOrchestrations/fetchWorkflows',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<IServerWorkflow[]>(`${BASE_URL}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const fetchWorkflowsNoLoading = createAsyncThunk(
  'runOrchestrations/fetchWorkflowsNoLoading',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<IServerWorkflow[]>(`${BASE_URL}`, {
        withCredentials: true,
      });
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const fetchWorkflowsNames = createAsyncThunk(
  'runOrchestrations/fetchWorkflowsNames',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get<string[]>(`${BASE_URL}/names/all`, {
        withCredentials: true,
      });
      return response.data ?? [];
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);

export const getWorkflowsByModule = createAsyncThunk<
  IOrchestrationsByModuleServer,
  IOrchestrationsByModuleServerRequest
>(
  'runOrchestrations/getWorkflowsByModule',
  async ({ slave, moduleTextId, moduleVersion }, { rejectWithValue }) => {
    try {
      const response = await axios.get<IOrchestrationsByModuleServer>(
        `${BASE_URL}/${slave}/${moduleTextId}/${moduleVersion}`,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      return handleThunkError(error, rejectWithValue);
    }
  }
);
