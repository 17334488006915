import style from './OverviewColumn.module.scss';
import {
  FieldErrorsImpl,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { InfoIconWithTooltip } from 'components/CustomIcons/InfoIconWithTooltip/InfoIconWithTooltip';
import { useAppSelector } from 'redux/hooks';
import { selectUserEmail } from 'redux/auth/AuthSlice';
import { TextField } from '@wk/components-v3-react16';
import { ICreateModuleOverviewNames } from 'interfaces/modules/module.interface';

interface IOverviewColumn {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}

const FIELD_ICON_CLASS = 'field-icon';
const fieldNameToLabelMapping = {
  moduleName: 'Module name',
  artifactId: 'Artifact ID',
  description: 'Description',
  authorName: 'Author name',
  email: 'Email',
  groupId: 'Group ID',
};

const OverviewColumn = ({
  register,
  setValue,
  setError,
  clearErrors,
  errors,
}: IOverviewColumn) => {
  const userEmailSelector = useAppSelector((state) => selectUserEmail(state));
  const handleInputChange =
    (name: ICreateModuleOverviewNames) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(name, e.target.value);
      if (e.target.value === '' || e.target.value === null) {
        const label = fieldNameToLabelMapping[name];
        setError(name, {
          type: 'required',
          message: `${label} should be not empty`,
        });
      } else {
        clearErrors(name);
      }
    };

  return (
    <CsbErrorBoundary>
      <div data-testid="OverviewColumn" className={style['container']}>
        <span className={style['title']}>Overview</span>
        <p className={style['subtitle']}>
          Use this page to create a downloadable Maven project/artifact for a
          new CSB Module with the required parameters, parameter types, and
          values. You can adjust the parameter sequence using drag-and-drop and
          dynamically view the artifact code as you work.
        </p>
        <form
          className={style['field-container']}
          data-testid="OverviewColumnFormSection"
        >
          <TextField
            label="Module name"
            labelFor="moduleNameInput"
            size="large"
            description={(errors?.moduleName?.message as string) ?? undefined}
          >
            <div className={style[FIELD_ICON_CLASS]}>
              <input
                type="text"
                id="moduleNameInput"
                maxLength={50}
                required
                placeholder="Enter the module display name"
                data-testid={`Input-moduleName`}
                {...register<ICreateModuleOverviewNames>('moduleName', {
                  onChange: handleInputChange('moduleName'),
                  required: 'Module name should be not empty',
                })}
              />
              <InfoIconWithTooltip
                size={23}
                tooltipPosition="right"
                wrapText={true}
                id={'moduleNameInputTooltip'}
                type="right"
                text="The database constraint for this property is NVARCHAR(50)."
              />
            </div>
          </TextField>
          <TextField
            label="Artifact ID"
            labelFor="artifactIdInput"
            size="large"
            description={(errors?.artifactId?.message as string) ?? undefined}
          >
            <div className={style[FIELD_ICON_CLASS]}>
              <input
                type="text"
                id="artifactIdInput"
                maxLength={50}
                required
                placeholder="Enter the Artifact ID (0-9,a-z,-)"
                data-testid={`Input-artifactId`}
                {...register<ICreateModuleOverviewNames>('artifactId', {
                  onChange: handleInputChange('artifactId'),
                  required: 'Artifact ID should be not empty',
                })}
              />
              <InfoIconWithTooltip
                size={23}
                tooltipPosition="right"
                wrapText={true}
                id={'mavenProjectNameInput'}
                type="right"
                text="The value you enter will become the ID of the created artifact. The database constraint for this property is NVARCHAR(50)."
              />
            </div>
          </TextField>
          <TextField label="Group ID" labelFor="groupIdInput" size="large">
            <div className={style[FIELD_ICON_CLASS]}>
              <input
                type="text"
                id="groupIdInput"
                data-testid={`Input-groupId`}
                {...register<ICreateModuleOverviewNames>('groupId', {
                  onChange: handleInputChange('groupId'),
                })}
                readOnly
              />
              <InfoIconWithTooltip
                size={23}
                tooltipPosition="right"
                wrapText={true}
                id={'groupIdInput'}
                type="right"
                text="This property is read-only. Its value is fixed and cannot be altered. It is inserted automatically into the module artifact's XML output."
              />
            </div>
          </TextField>
          <TextField
            label="Description"
            labelFor="descriptionInput"
            size="large"
            description={(errors?.description?.message as string) ?? undefined}
          >
            <div className={style[FIELD_ICON_CLASS]}>
              <input
                type="text"
                id="descriptionInput"
                maxLength={300}
                required
                placeholder="Describe what the module does"
                data-testid={`Input-description`}
                {...register<ICreateModuleOverviewNames>('description', {
                  onChange: handleInputChange('description'),
                  required: 'Description should be not empty',
                })}
              />
              <InfoIconWithTooltip
                size={23}
                tooltipPosition="right"
                wrapText={true}
                id={'descriptionInput'}
                type="right"
                text="This value should not be empty. The database constraint for this property is NVARCHAR(300)."
              />
            </div>
          </TextField>
          <TextField
            label="Author name"
            labelFor="authorNameInput"
            size="large"
            description={(errors?.authorName?.message as string) ?? undefined}
          >
            <div className={style[FIELD_ICON_CLASS]}>
              <input
                type="text"
                id="authorNameInput"
                required
                placeholder="Given name and family name"
                data-testid={`Input-authorName`}
                {...register<ICreateModuleOverviewNames>('authorName', {
                  onChange: handleInputChange('authorName'),
                  required: 'Author name should be not empty',
                })}
              />
              <InfoIconWithTooltip
                size={23}
                tooltipPosition="right"
                wrapText={true}
                id={'authorNameInputTooltip'}
                type="right"
                text="This property is a string and is not stored in the database."
              />
            </div>
          </TextField>
          <TextField
            label="Email"
            labelFor="emailInput"
            size="large"
            description={(errors?.email?.message as string) ?? undefined}
          >
            <div className={style[FIELD_ICON_CLASS]}>
              <input
                type="email"
                id="emailInput"
                required
                placeholder="Logged in developer's email address"
                data-testid={`Input-email`}
                readOnly
                value={userEmailSelector}
                {...register<ICreateModuleOverviewNames>('email', {
                  onChange: handleInputChange('email'),
                  required: 'Email should be not empty',
                })}
              />
              <InfoIconWithTooltip
                size={23}
                tooltipPosition="right"
                wrapText={true}
                id={'emailInputTooltip'}
                type="right"
                text="This property is prepopulated with the logged-in user's email address and is not stored in the database."
              />
            </div>
          </TextField>
        </form>
      </div>
    </CsbErrorBoundary>
  );
};

export default OverviewColumn;
