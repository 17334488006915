import {
  ButtonField,
  ButtonGroup,
  ButtonGroupItem,
  Iconsvg,
} from '@wk/components-react16';
import {
  useSubmitWorkflowMutation,
  useUploadContentMutation,
} from 'api/orchestrations/orchestrationApi';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
  closeModal,
  selectAdditionalData,
  selectModalName,
  setSidebarNavigated,
} from 'redux/modal/ModalSlice';
import {
  selectSelectedWorkflow,
  selectWizardActiveIndex,
  setSelectedWorkflow,
  setWizardActiveIndex,
} from 'redux/runOrchestrations/RunOrchestrationsSlice';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { MODAL_ACTIONS } from 'interfaces/modals/closeModalInfo.interface';
import { IPreSubmitWorkflow } from 'interfaces/modals/configureAndRun.interface';
import { useEffect, useState } from 'react';
import {
  createFilename,
  formatJobRequest,
  showToastAndClose,
} from 'utils/commonFunctions/CommonFunctions';
import { SPINE_MENU_ITEMS, history } from 'utils/common-constants';
import {
  FieldErrorsImpl,
  FieldValues,
  UseFormHandleSubmit,
  UseFormReset,
} from 'react-hook-form';
import { MODAL_NAMES } from 'components/Modals/modal-list';
import {
  ICreateProduct,
  IPreCreateProduct,
} from 'interfaces/modals/createProduct.interface';
import { useCreateProductMutation } from 'api/workflows/workflows';
import { IWorkflow } from 'interfaces/runOrchestration/workflowRow';
import { mapProductToTable } from 'dto/runOrchestration/productToTable';

export interface ICancelNextFooter {
  reset: UseFormReset<FieldValues>;
  wizardStartingIndex?: any;
  handleSubmit: UseFormHandleSubmit<any>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}

const CancelNextFooter = ({
  reset,
  wizardStartingIndex,
  handleSubmit,
  errors,
}: ICancelNextFooter) => {
  const dispatch = useAppDispatch();
  const [submitWokflow, { isLoading }] = useSubmitWorkflowMutation();
  const [submitCreateProduct, { isLoading: isProductCreationLoading }] =
    useCreateProductMutation();
  const [uploadFiles] = useUploadContentMutation();
  const wizardActiveindex = useAppSelector(selectWizardActiveIndex);
  const modalAdditionalData = useAppSelector(selectAdditionalData);
  const modalName = useAppSelector(selectModalName);
  const modalInfo = useAppSelector((state) => state.modal);
  const [isWizardLastStep, setIsWizardLastStep] = useState<boolean | undefined>(
    undefined
  );
  const selectedWorkflow = useAppSelector(selectSelectedWorkflow);

  const handleUserRequest = (action: MODAL_ACTIONS) => {
    if (action === MODAL_ACTIONS.cancel) {
      reset();
      dispatch(
        closeModal({
          id: modalInfo.id,
          action,
        })
      );
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setWizardActiveIndex(wizardStartingIndex ?? 0));
    };
  }, []);

  useEffect(() => {
    if (
      (modalAdditionalData?.wizardItems?.length &&
        wizardActiveindex === modalAdditionalData?.wizardItems?.length - 1) ||
      modalAdditionalData?.wizardItems?.length === 0
    ) {
      setIsWizardLastStep(true);
    } else {
      setIsWizardLastStep(false);
    }
  }, [modalAdditionalData, wizardActiveindex]);

  const onSubmit = async (data: IPreSubmitWorkflow | IPreCreateProduct) => {
    if (modalName === MODAL_NAMES.CONFIGURE_AND_RUN) {
      submitWorkflow(
        data as IPreSubmitWorkflow,
        submitWokflow,
        uploadFiles,
        handleUserRequest,
        dispatch
      );
    } else if (
      modalName === MODAL_NAMES.CREATE_PRODUCT ||
      MODAL_NAMES.EDIT_PRODUCT
    ) {
      await handleProductCreationOrEdition(
        data as IPreCreateProduct,
        submitCreateProduct,
        handleUserRequest,
        dispatch,
        modalName === MODAL_NAMES.EDIT_PRODUCT ? true : false,
        selectedWorkflow as IWorkflow
      );
    }
    reset();
  };

  return (
    <CsbErrorBoundary>
      {isWizardLastStep !== undefined && (
        <ButtonGroup mode="static">
          <ButtonGroupItem slot="buttonGroupItem">
            <ButtonField mode={'text'}>
              <button
                type="button"
                id="cancel"
                onClick={() => handleUserRequest(MODAL_ACTIONS.cancel)}
                data-testid="CancelButton"
                disabled={isLoading || isProductCreationLoading}
              >
                Cancel
              </button>
            </ButtonField>
          </ButtonGroupItem>
          {wizardActiveindex > 0 && (
            <ButtonGroupItem slot="buttonGroupItem">
              <ButtonField mode={'ghost'} iconPosition={'left'}>
                <button
                  type="button"
                  id="cancel"
                  onClick={() =>
                    dispatch(setWizardActiveIndex(wizardActiveindex - 1))
                  }
                  data-testid="PreviousButton"
                  disabled={isLoading || isProductCreationLoading}
                >
                  <Iconsvg name="arrow-left"></Iconsvg>
                  Previous
                </button>
              </ButtonField>
            </ButtonGroupItem>
          )}
          <ButtonGroupItem slot="buttonGroupItem">
            <ButtonField mode={'default'} iconPosition={'right'}>
              {isWizardLastStep ? (
                <button
                  type="button"
                  data-testid="SubmitButton"
                  id="next"
                  onClick={handleSubmit(onSubmit)}
                  style={{
                    marginLeft: `${wizardActiveindex > 0 ? '1rem' : '0'}`,
                  }}
                  disabled={
                    isLoading ||
                    Object.keys(errors).length > 0 ||
                    isProductCreationLoading
                  }
                >
                  {modalName === MODAL_NAMES.CREATE_PRODUCT
                    ? 'Create product'
                    : modalName === MODAL_NAMES.EDIT_PRODUCT
                    ? 'Edit product'
                    : 'Submit'}
                  <Iconsvg name="arrow-right"></Iconsvg>
                </button>
              ) : (
                <button
                  type="button"
                  data-testid="NextButton"
                  id="next"
                  onClick={() =>
                    dispatch(setWizardActiveIndex(wizardActiveindex + 1))
                  }
                  style={{
                    marginLeft: `${wizardActiveindex > 0 ? '1rem' : '0'}`,
                  }}
                  disabled={Object.keys(errors).length > 0}
                >
                  Next
                  <Iconsvg name="arrow-right"></Iconsvg>
                </button>
              )}
            </ButtonField>
          </ButtonGroupItem>
        </ButtonGroup>
      )}
    </CsbErrorBoundary>
  );
};

const handleFilesUpload = async (
  jobRequest: any,
  content: File[],
  uploadFiles: (...args: any) => any
) => {
  const FIVE_MB = 5 * 1024 * 1024;
  if (
    content?.length > 1 ||
    (content?.length === 1 && content[0].size > FIVE_MB)
  ) {
    const filesResponse = await uploadFiles(content)
      .unwrap()
      .catch((error: any) => {
        return error;
      });
    const fileName = createFilename(content);
    delete jobRequest.data.content;
    return {
      ...jobRequest,
      data: {
        ...jobRequest.data,
        content: { name: fileName, contentId: filesResponse },
      },
    };
  } else {
    return new Promise((resolve, reject) => {
      let fileData;
      const reader = new FileReader();
      reader.onload = (e) => {
        if (typeof reader.result === 'string' && e.target != null) {
          fileData = {
            base64data: btoa(e.target.result as any),
            name: content?.[0]?.name,
          };
          resolve({
            ...jobRequest,
            data: {
              ...jobRequest.data,
              content: fileData,
            },
          });
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsBinaryString(content[0]);
    });
  }
};

const organizeProductData = (
  data: IPreCreateProduct,
  isEditingProduct: boolean
) => {
  const organizedProduct: ICreateProduct = {
    productId: isEditingProduct ? data.productId : null,
    name: data.name,
    throttleRate: data.throttleRate,
    workflowId: data.workflowId,
    cronTrigger: data.cronTrigger,
    businessProduct: data.businessProduct,
    cronTimezone: data.cronTimezone,
    sftpDropzone: data.sftpDropzone,
    sftpPrivateKey: data.sftpPrivateKey,
    ftpDropzone: data.ftpDropzone,
    s3Dropzone: data.s3Dropzone,
    notifyEmails: data.notifyEmails,
    eventParametersMapping: data.eventParametersMapping,
    reportingIndex: data.reportingIndex,
    unpackInput: data.unpackInput,
    params: [],
  };
  if (data.params) {
    const paramMap = new Map<number, { paramId: number; value: any }>();

    Object.keys(data.params).forEach((key) => {
      let paramId: number | undefined;
      let value: any;
      const param: { paramId?: number; value?: any } | any = data.params?.[key];

      if (typeof param === 'object' && 'paramId' in param) {
        paramId = param.paramId;
        value = param.value;
      } else {
        const match = key.match(/(\d+)$/);
        if (match) {
          paramId = parseInt(match[0], 10);
          value = param;
        }
      }

      if (paramId !== undefined && value !== '') {
        paramMap.set(paramId, { paramId, value });
      }
    });

    organizedProduct.params = Array.from(paramMap.values());
  }

  return organizedProduct;
};

const handleProductCreationOrEdition = async (
  data: IPreCreateProduct,
  submitCreateProduct: any,
  handleUserRequest: (...args: any) => void,
  dispatch: (...args: any) => void,
  isEditingProduct: boolean,
  selectedWorkflow: IWorkflow
) => {
  const newProduct: ICreateProduct = organizeProductData(
    data,
    isEditingProduct
  );
  try {
    const response = await submitCreateProduct(newProduct).unwrap();
    if (isEditingProduct) {
      showToastAndClose(
        'success',
        'informationToast',
        handleUserRequest,
        dispatch,
        { text: 'Your product variant has been updated' }
      );
    } else {
      dispatch(setSelectedWorkflow(response));
      setTimeout(() => {
        const product = mapProductToTable(response, selectedWorkflow);
        dispatch(setSelectedWorkflow(product));
      }, 1000);
      showToastAndClose(
        'success',
        'informationToast',
        handleUserRequest,
        dispatch,
        { text: 'Product created successfully' }
      );
    }
  } catch (error) {
    console.error(error);
    if (isEditingProduct) {
      showToastAndClose(
        'error',
        'informationToast',
        handleUserRequest,
        dispatch,
        {
          text: `Sorry, we couldn't update your product, Error message ${
            (error as any)?.data ?? 'No error message available'
          }`,
        }
      );
    } else {
      showToastAndClose(
        'error',
        'informationToast',
        handleUserRequest,
        dispatch,
        {
          text: `Sorry, we couldn't create your product, Error message: ${
            (error as any)?.data ?? 'No error message available'
          }, please try again`,
        }
      );
    }
  }
};

const submitWorkflow = async (
  data: IPreSubmitWorkflow,
  submitWokflow: any,
  uploadFiles: (...args: any) => any,
  handleUserRequest: (...args: any) => void,
  dispatch: (...args: any) => void
) => {
  let jobRequest = formatJobRequest(data);
  if (data?.content && data.content?.length > 0) {
    jobRequest = await handleFilesUpload(jobRequest, data.content, uploadFiles);
    try {
      const responseId = await submitWokflow({
        ...jobRequest,
      }).unwrap();
      showToastAndClose(
        'success',
        'configureAndRunModal',
        handleUserRequest,
        dispatch
      );
      dispatch(setSidebarNavigated(SPINE_MENU_ITEMS.VIEW_ORCHESTRATION));
      (history as any)?.navigate(`/dashboard/orchestration/${responseId}`);
    } catch (error) {
      console.error(error);
      showToastAndClose(
        'error',
        'configureAndRunModal',
        handleUserRequest,
        dispatch
      );
    }
  } else {
    try {
      const responseId = await submitWokflow({
        ...jobRequest,
      }).unwrap();
      showToastAndClose(
        'success',
        'configureAndRunModal',
        handleUserRequest,
        dispatch
      );
      dispatch(setSidebarNavigated(SPINE_MENU_ITEMS.VIEW_ORCHESTRATION));
      (history as any)?.navigate(`/dashboard/orchestration/${responseId}`);
    } catch (error) {
      console.error(error);
      showToastAndClose(
        'error',
        'configureAndRunModal',
        handleUserRequest,
        dispatch
      );
    }
  }
};

export default CancelNextFooter;
