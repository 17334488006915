import {
  ButtonField,
  ButtonGroup,
  ButtonGroupItem,
} from '@wk/components-react16';
import { usePostSendServiceNowEmailMutation } from 'api/orchestrations/orchestrationApi';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { closeModal } from 'redux/modal/ModalSlice';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { MODAL_ACTIONS } from 'interfaces/modals/closeModalInfo.interface';
import { IServiceNowEmail } from 'interfaces/serviceNowModal';
import { showToastAndClose } from 'utils/commonFunctions/CommonFunctions';

const CancelSubmitFooter = (props: any) => {
  const dispatch = useAppDispatch();
  const modalInfo = useAppSelector((state) => state.modal);
  const [sendServiceNowEmail, { isLoading }] =
    usePostSendServiceNowEmailMutation();

  const handleUserRequest = (action: MODAL_ACTIONS) => {
    if (action === MODAL_ACTIONS.cancel) {
      props.reset();
      dispatch(
        closeModal({
          id: modalInfo.id,
          action,
        })
      );
    }
  };

  const onSubmit = async (data: {
    action: string;
    orchestrationId: string;
    additionalInformation: string;
  }) => {
    if (data.action === 'serviceNow') {
      const formatData: IServiceNowEmail = {
        orchestrationId: data.orchestrationId,
        additionalInformation: data.additionalInformation,
      };
      try {
        const response = await sendServiceNowEmail(formatData);
        if (response.hasOwnProperty('data')) {
          showToastAndClose(
            'success',
            'serviceNow',
            handleUserRequest,
            dispatch
          );
        } else {
          showToastAndClose('error', 'serviceNow', handleUserRequest, dispatch);
        }
      } catch (error) {
        showToastAndClose('error', 'serviceNow', handleUserRequest, dispatch);
      }
    }
  };

  return (
    <CsbErrorBoundary>
      <ButtonGroup mode="static">
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'text'}>
            <button
              type="button"
              id="cancel"
              onClick={() => handleUserRequest(MODAL_ACTIONS.cancel)}
              data-testid="CancelButton"
              disabled={isLoading}
            >
              Cancel
            </button>
          </ButtonField>
        </ButtonGroupItem>
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'default'}>
            <button
              type="button"
              data-testid="SubmitButton"
              id="submit"
              onClick={props.handleSubmit(onSubmit)}
              disabled={isLoading}
            >
              Submit
            </button>
          </ButtonField>
        </ButtonGroupItem>
      </ButtonGroup>
    </CsbErrorBoundary>
  );
};

export default CancelSubmitFooter;
