import { useEffect, useMemo, useRef, useState } from 'react';
import style from './DragAndDropList.module.scss';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { DragAndDropIcon } from 'components/CustomIcons/DragAndDropIcon';

interface IDragAndDropList {
  items: any[];
  onDrop: (newList: any[]) => void;
  selectedItemId?: string;
  onSelect: (newItem: any) => void;
  labelKey?: string;
  defaultDisplayLabel?: string;
}

const DragAndDropList = ({
  items,
  onDrop,
  selectedItemId,
  onSelect,
  labelKey = 'label',
  defaultDisplayLabel = 'Item',
}: IDragAndDropList) => {
  const [draggedItem, setDraggedItem] = useState<any | null>(null);
  const [highlightedIndex, setHighlightedIndex] = useState<number | null>(null);
  const lastItemRef = useRef<HTMLDivElement | null>(null);
  const localList = useMemo(() => {
    return [...items];
  }, [items]);
  const draggedIndex = useMemo(
    () => localList.indexOf(draggedItem as string),
    [localList, draggedItem]
  );
  const highlightedClass = useMemo(() => {
    if (highlightedIndex !== null && draggedIndex < highlightedIndex) {
      return 'highlighted-bottom';
    } else if (highlightedIndex !== null && draggedIndex > highlightedIndex) {
      return 'highlighted-top';
    } else {
      return '';
    }
  }, [draggedIndex, highlightedIndex]);

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, item: any) => {
    setDraggedItem(item);
    e.currentTarget.style.opacity = '0.5';
    e.currentTarget.style.cursor = 'move';
  };

  const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    e.currentTarget.style.opacity = '1';
    e.currentTarget.style.cursor = 'pointer';
    setHighlightedIndex(null);
  };

  const handleDragOver = (
    e: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    e.preventDefault();
    if (highlightedIndex !== index) {
      setHighlightedIndex(index);
    }
  };

  const handleDrop = (
    e: React.DragEvent<HTMLDivElement>,
    targetItem: string
  ) => {
    e.preventDefault();
    const targetIndex = localList.indexOf(targetItem);

    const newList = [...localList];
    newList.splice(draggedIndex, 1);
    newList.splice(targetIndex, 0, draggedItem as string);

    onDrop(newList);

    setDraggedItem(null);
    setHighlightedIndex(null);
  };

  useEffect(() => {
    if (lastItemRef.current) {
      lastItemRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    }
  }, [localList.length]);

  return (
    <CsbErrorBoundary>
      <div data-testid="DragAndDropList">
        {localList?.map((item, index) => (
          <div
            ref={index === localList.length - 1 ? lastItemRef : null}
            className={`${style['list-item']} ${
              selectedItemId === item.id ? style['selected-item'] : ''
            } ${index === highlightedIndex ? style[highlightedClass] : ''}`}
            key={`${item.id}-${index}`}
            draggable
            onDragStart={(e) => handleDragStart(e, item)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDragEnd={handleDragEnd}
            onDrop={(e) => handleDrop(e, item)}
            onClick={() => onSelect(item)}
          >
            <DragAndDropIcon />
            <span>{item[labelKey] || defaultDisplayLabel}</span>
          </div>
        ))}
      </div>
    </CsbErrorBoundary>
  );
};

export default DragAndDropList;
