import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-xml';
import { useAppSelector } from 'redux/hooks';
import { selectParameterList } from 'redux/createModule/CreateModuleSlice';
import { IModuleParameterItem } from 'interfaces/modules/module.interface';
import { useEffect, useRef } from 'react';
import useDynamicHeight from '../../hooks/useDynamicHeight';

interface IManifestSourceCode {}

const ManifestSourceCode = ({}: IManifestSourceCode) => {
  const name = '${project.name}';
  const artifactId = '${project.artifactId}';
  const version = '${project.version}';
  const description = '${project.description}';
  const moduleParameters = useAppSelector(selectParameterList);
  const heightOffset = useDynamicHeight();
  const editorRef = useRef<AceEditor | null>(null);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current!.editor.resize();
    }
  }, [heightOffset]);

  const xmlCode = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<module xmlns="http://www.wolterskluwer.com/csb/module">
    <metadata>
      <name>${name}</name>
      <textId>${artifactId}</textId>
      <version>${version}</version>
      <description>${description}</description>
      <parameters>
      ${generateParametersXML(moduleParameters)}
      </parameters>
    </metadata>
</module>`;

  return (
    <CsbErrorBoundary>
      <div data-testid="ManifestSourceCode">
        <AceEditor
          mode="xml"
          name="manifest_editor"
          width="100%"
          height={`calc(80vh - ${heightOffset}px)`}
          value={xmlCode}
          showPrintMargin={false}
          editorProps={{ $blockScrolling: true }}
          setOptions={{ useWorker: false, wrap: true }}
        />
      </div>
    </CsbErrorBoundary>
  );
};

const generateParametersXML = (parameters: IModuleParameterItem[]) => {
  return '';
};

export default ManifestSourceCode;
