import {
  ButtonField,
  ButtonGroup,
  ButtonGroupItem,
} from '@wk/components-react16';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { closeModal } from 'redux/modal/ModalSlice';
import { MODAL_ACTIONS } from 'interfaces/modals/closeModalInfo.interface';
import { resetPaginatorInfo } from 'redux/dashboard/DashboardSlice';
import { forwardRef } from 'react';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';

const CancelResetApplyFooter = forwardRef((props, ref: any) => {
  const dispatch = useAppDispatch();
  const { resetRef, applyRef, customRef } = ref;
  const modalInfo = useAppSelector((state) => state.modal);

  const handleUserRequest = (action: MODAL_ACTIONS) => {
    if (action === MODAL_ACTIONS.reset) {
      dispatch(resetPaginatorInfo());
      customRef.current?.onResetFromModal();
    }
    dispatch(
      closeModal({
        id: modalInfo.id,
        action,
      })
    );
  };

  const handleSaveFn = () => {
    dispatch(resetPaginatorInfo());
    const result = customRef.current?.onSaveFromModal();
    dispatch(
      closeModal({
        id: modalInfo.id,
        action: MODAL_ACTIONS.save,
        extraData: result,
      })
    );
  };

  return (
    <CsbErrorBoundary>
      <ButtonGroup mode="static">
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'text'}>
            <button
              type="button"
              id="cancel"
              onClick={() => handleUserRequest(MODAL_ACTIONS.cancel)}
              data-testid="CancelButton"
            >
              Cancel
            </button>
          </ButtonField>
        </ButtonGroupItem>
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'ghost'}>
            <button
              type="button"
              id="reset"
              data-testid="ResetButton"
              onClick={() => handleUserRequest(MODAL_ACTIONS.reset)}
              ref={resetRef}
            >
              Reset
            </button>
          </ButtonField>
        </ButtonGroupItem>
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField>
            <button
              type="button"
              id="save"
              data-testid="SaveButton"
              onClick={() => handleSaveFn()}
              ref={applyRef}
            >
              Apply
            </button>
          </ButtonField>
        </ButtonGroupItem>
      </ButtonGroup>
    </CsbErrorBoundary>
  );
});

export default CancelResetApplyFooter;
