import { TextField } from '@wk/components-v3-react16';
import { SelectField, CheckboxField } from '@wk/components-react16';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { IModuleParameterItem } from 'interfaces/modules/module.interface';
import { updateParameterProps } from 'redux/createModule/CreateModuleSlice';
import { useAppDispatch } from 'redux/hooks';
import { CREATE_MODULE_DEFAULT_PARAMETER_NAME } from 'utils/common-constants';
import style from './ParametersDetailsInputs.module.scss';
import { InfoIconWithTooltip } from 'components/CustomIcons/InfoIconWithTooltip/InfoIconWithTooltip';

interface IParametersDetailsInputs {
  selectedParameter: IModuleParameterItem;
  parameterList: IModuleParameterItem[];
}

const FIELD_ICON_CLASS = 'field-icon';

const ParametersDetailsInputs = ({
  selectedParameter,
  parameterList,
}: IParametersDetailsInputs) => {
  const dispatch = useAppDispatch();

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { target } = event;
    let value =
      target.type === 'checkbox'
        ? (target as HTMLInputElement).checked
        : target.value;

    if (target.id === 'paramId' && typeof value === 'string') {
      value = value.replace(/\s/g, '');
    }

    dispatch(
      updateParameterProps({
        id: selectedParameter.id,
        newSelected: { ...selectedParameter, [target.id]: value },
      })
    );
  };

  return (
    <CsbErrorBoundary>
      <section
        data-testid="ParametersDetailsInputs"
        className={style['inputs-container']}
      >
        <TextField label="Parameter ID" labelFor="paramId">
          <div className={style[FIELD_ICON_CLASS]}>
            <input
              data-testid="ParametersDetailsId"
              placeholder="Enter parameter ID (0-9, a-z, A-Z)"
              id="paramId"
              type="text"
              maxLength={50}
              value={selectedParameter?.paramId ?? ''}
              onChange={handleInputChange}
            />
            <InfoIconWithTooltip
              id={`${selectedParameter.id}-value`}
              type={null}
              size={24}
              text="The value you enter will become the ID of the created parameter. The database constraint for this property is NVARCHAR(50)."
              wrapText={true}
              fixedPosition={true}
            />
          </div>
        </TextField>
        <TextField label="Parameter display name" labelFor="paramName">
          <div className={style[FIELD_ICON_CLASS]}>
            <input
              data-testid="ParametersDetailsParamName"
              placeholder="Enter the parameter display name"
              id="paramName"
              type="text"
              maxLength={50}
              value={
                selectedParameter?.paramName ===
                CREATE_MODULE_DEFAULT_PARAMETER_NAME
                  ? ''
                  : selectedParameter?.paramName || ''
              }
              onChange={handleInputChange}
            />
            <InfoIconWithTooltip
              id={`${selectedParameter.id}-name-value`}
              type={null}
              size={24}
              text="The database constraint for this property is NVARCHAR(50)."
              wrapText={true}
              fixedPosition={true}
            />
          </div>
        </TextField>
        <ValueInput
          selectedParameter={selectedParameter}
          handleInputChange={handleInputChange}
        />
        <div className={style['depends-on-container']}>
          <div className={style['checkbox-field-icon']}>
            <CheckboxField label="Is required">
              <input
                data-testid="isRequiredInput"
                id="isRequired"
                name="isRequired"
                flow-id="checkbox"
                type="checkbox"
                onChange={handleInputChange}
                checked={
                  selectedParameter.isRequired !== undefined
                    ? !!selectedParameter.isRequired
                    : true
                }
              />
              <InfoIconWithTooltip
                id={`${selectedParameter.id}-value`}
                type={null}
                size={24}
                text="Mandatory parameter if checked. Non-mandatory if unchecked"
                wrapText={true}
                fixedPosition={true}
              />
            </CheckboxField>
          </div>
          <div className={style['labels-container']}>
            <span>Depends on</span>
            <p>
              Select the required "Depends on" parameter from the drop-down.
            </p>
          </div>
          <SelectField label="EnumTextId" labelFor="dependsOn">
            <div className={style[FIELD_ICON_CLASS]}>
              <select
                id="dependsOn"
                value={selectedParameter.dependsOn || ''}
                onChange={handleInputChange}
              >
                <option value="">Select the EnumTextId</option>
                {parameterList.map(({ id, type, paramId }) => {
                  if (type === 'ENUM' && id !== selectedParameter.id) {
                    return (
                      <option key={id} value={paramId} label={paramId}>
                        {paramId}
                      </option>
                    );
                  }
                  return null;
                })}
              </select>
              <InfoIconWithTooltip
                id={`${selectedParameter.id}-enumTextId-value`}
                type={null}
                size={24}
                text="The database constraint for this property is NVARCHAR(50). If the Enum value is not empty, this value should not be empty."
                wrapText={true}
                fixedPosition={true}
              />
            </div>
          </SelectField>
          <TextField label="EnumValue" labelFor="enumValue">
            <div className={style[FIELD_ICON_CLASS]}>
              <input
                data-testid="EnumValueInput"
                placeholder="Enter the EnumValue"
                id="enumValue"
                type="text"
                maxLength={300}
                value={selectedParameter?.enumValue || ''}
                onChange={handleInputChange}
              />
              <InfoIconWithTooltip
                id={`${selectedParameter.id}-enumValue-value`}
                type={null}
                size={24}
                text="The database constraint for this property is NVARCHAR(300). If EnumTextId is not empty, this value should not be empty."
                wrapText={true}
                fixedPosition={true}
              />
            </div>
          </TextField>
        </div>
      </section>
    </CsbErrorBoundary>
  );
};

const ValueInput = ({
  selectedParameter,
  handleInputChange,
}: {
  selectedParameter: IModuleParameterItem;
  handleInputChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}) => {
  return (
    <>
      {(selectedParameter.type === 'ENUM' ||
        selectedParameter.type === 'MULTI') && (
        <TextField label="Values" labelFor="values">
          <div className={style[FIELD_ICON_CLASS]}>
            <input
              data-testid="MultiValuesInput"
              placeholder="Value1|Value2|ValueN"
              id="values"
              type="text"
              value={selectedParameter?.values || ''}
              onChange={handleInputChange}
            />
            <InfoIconWithTooltip
              id={`${selectedParameter.id}-multi-value`}
              type={null}
              size={24}
              text="The database constraint for this property is MediumText (16MB)."
              wrapText={true}
              fixedPosition={true}
            />
          </div>
        </TextField>
      )}
      {selectedParameter.type === 'BOOLEAN' ? (
        <div className={style['checkbox-field-icon']}>
          <CheckboxField label="Default value">
            <input
              data-testid="ValueInput"
              id="value"
              type="checkbox"
              checked={
                selectedParameter.value !== undefined
                  ? !!selectedParameter.value
                  : true
              }
              onChange={handleInputChange}
            />
          </CheckboxField>
          <InfoIconWithTooltip
            id={`${selectedParameter.id}-value`}
            type={null}
            size={24}
            text="This value is applied when the user drags and drops the module on the Design Orchestration screen."
            wrapText={true}
            fixedPosition={true}
          />
        </div>
      ) : selectedParameter.type === 'ENUM' ||
        selectedParameter.type === 'MULTI' ? (
        <TextField label="Default values" labelFor="value">
          <div className={style[FIELD_ICON_CLASS]}>
            <input
              data-testid="ValueInput"
              placeholder="Enter the default values as DefaultValue1|DefaultValue2|DefaultValueN"
              id="value"
              type="text"
              value={(selectedParameter?.value as string) || ''}
              onChange={handleInputChange}
            />
            <InfoIconWithTooltip
              id={`${selectedParameter.id}-multiDefault-value`}
              type={null}
              size={24}
              text="This value is applied when the user drags and drops the module on the Design Orchestration screen."
              wrapText={true}
              fixedPosition={true}
            />
          </div>
        </TextField>
      ) : (
        <TextField label="Default value" labelFor="value">
          <div className={style[FIELD_ICON_CLASS]}>
            <input
              data-testid="ValueInput"
              placeholder="Enter the default value"
              id="value"
              type="text"
              maxLength={300}
              value={(selectedParameter?.value as string) || ''}
              onChange={handleInputChange}
            />
            <InfoIconWithTooltip
              id={`${selectedParameter.id}-default-value`}
              type={null}
              size={24}
              text="This value is applied when the user drags and drops the module on the Design Orchestration screen. The database constraint for this property is NVARCHAR(300)."
              wrapText={true}
              fixedPosition={true}
            />
          </div>
        </TextField>
      )}
    </>
  );
};

export default ParametersDetailsInputs;
