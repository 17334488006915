import {
  FieldErrorsImpl,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { Tabs, TabHeader, TabPanel } from '@wk/components-react16';
import CreateModuleNormalTab from './components/CreateModuleNormalTab/CreateModuleNormalTab';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { CREATE_MODULE_TABS } from 'utils/common-constants';
import style from './CreateModuleModal.module.scss';
import CreateModuleSourceTab from './components/CreateModuleSourceTab/CreateModuleSourceTab';

interface ICreateModuleModal {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}

const CreateModuleModal = ({
  register,
  setValue,
  setError,
  clearErrors,
  errors,
}: ICreateModuleModal) => {
  return (
    <CsbErrorBoundary>
      <div
        data-testid="CreateModuleModal"
        className={style['module-modal-content']}
      >
        <Tabs mode="classic">
          {CREATE_MODULE_TABS.map(({ id, label }) => (
            <TabHeader key={id} slot="tabHeader">
              {label}
            </TabHeader>
          ))}
          <TabPanel data-testid="CreateModuleFirstTabPanel" slot="tabPanel">
            <CreateModuleNormalTab
              register={register}
              setValue={setValue}
              setError={setError}
              clearErrors={clearErrors}
              errors={errors}
            />
          </TabPanel>
          <TabPanel data-testid="CreateModuleSecondTabPanel" slot="tabPanel">
            <CreateModuleSourceTab />
          </TabPanel>
        </Tabs>
      </div>
    </CsbErrorBoundary>
  );
};

export default CreateModuleModal;
