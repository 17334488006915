import {
  ButtonField,
  ButtonGroup,
  ButtonGroupItem,
} from '@wk/components-react16';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { closeModal, setAdditionalData } from 'redux/modal/ModalSlice';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { MODAL_ACTIONS } from 'interfaces/modals/closeModalInfo.interface';
import {
  deployModule,
  IDeployModuleReq,
} from 'api/manageModules/manageModulesThunks';
import {
  IDeployModuleForm,
  IDeployModuleNames,
} from 'interfaces/modules/module.interface';
import { showToastAndClose } from 'utils/commonFunctions/CommonFunctions';
import { useState } from 'react';
import {
  FieldValues,
  UseFormHandleSubmit,
  UseFormReset,
  UseFormWatch,
} from 'react-hook-form';
import useFetchDataInterval from 'utils/hooks/manageModules/useFetchDataInterval';

interface ICancelDeployFooterProps {
  watch: UseFormWatch<FieldValues>;
  reset: UseFormReset<FieldValues>;
  handleSubmit: UseFormHandleSubmit<any>;
}

const CancelDeployFooter = ({
  watch,
  reset,
  handleSubmit,
}: ICancelDeployFooterProps) => {
  const dispatch = useAppDispatch();
  const modalInfo = useAppSelector((state) => state.modal);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const selectedModule = watch('selectedModule' as IDeployModuleNames);
  const selectedServers = watch('selectedServers' as IDeployModuleNames);
  const MODULE_DEPLOYMENT_ERROR_STRING = 'Module';
  const { startModulesFetchingInterval, resetInterval } =
    useFetchDataInterval();

  const handleUserRequest = (action: MODAL_ACTIONS) => {
    if (action === MODAL_ACTIONS.cancel) {
      reset();
      dispatch(
        closeModal({
          id: modalInfo.id,
          action,
        })
      );
    }
  };

  const onSubmit = async (data: IDeployModuleForm) => {
    setIsLoading(true);
    dispatch(setAdditionalData({ isLoading: true }));
    const submitData: IDeployModuleReq = {
      selectedModule: data.selectedModule,
      selectedVersion: data.selectedVersion,
      selectedServers: data.selectedServers,
    };

    try {
      await dispatch(deployModule(submitData)).unwrap();
      showToastAndClose(
        'success',
        'informationToast',
        handleUserRequest,
        dispatch,
        {
          text: `The deployment of Module (${submitData.selectedModule}, ${submitData.selectedVersion}) was completed successfully on the selected servers`,
        }
      );
      resetInterval();
      startModulesFetchingInterval();
    } catch (error) {
      const errorMessage =
        typeof error === 'string' &&
        error.includes(MODULE_DEPLOYMENT_ERROR_STRING)
          ? error
          : `The deployment of Module (${submitData.selectedModule}, ${
              submitData.selectedVersion
            }) was completed successfully on 0 server(s). Deployment failed on: ${submitData.selectedServers?.join(
              ', '
            )}".`;
      showToastAndClose(
        'error',
        'informationToast',
        handleUserRequest,
        dispatch,
        {
          text: errorMessage,
        }
      );
    }
    setIsLoading(false);
    dispatch(setAdditionalData({ isLoading: false }));
    reset();
  };

  return (
    <CsbErrorBoundary>
      <ButtonGroup mode="static">
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'text'}>
            <button
              type="button"
              id="cancel"
              onClick={() => handleUserRequest(MODAL_ACTIONS.cancel)}
              data-testid="CancelButton"
              disabled={isLoading}
            >
              Cancel
            </button>
          </ButtonField>
        </ButtonGroupItem>
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'default'}>
            <button
              type="button"
              data-testid="SubmitButton"
              id="submit"
              onClick={handleSubmit(onSubmit)}
              disabled={
                !selectedModule ||
                !selectedServers ||
                selectedServers.length === 0 ||
                isLoading
              }
            >
              Deploy
            </button>
          </ButtonField>
        </ButtonGroupItem>
      </ButtonGroup>
    </CsbErrorBoundary>
  );
};

export default CancelDeployFooter;
