export interface DragAndDropIconProps {
  width?: number;
  height?: number;
}
export const DragAndDropIcon = ({ width, height }: DragAndDropIconProps) => (
  <svg
    width={width || '9'}
    height={height || '16'}
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="7.5"
      cy="14"
      r="1.5"
      transform="rotate(90 7.5 14)"
      fill="#6D7172"
    />
    <circle
      cx="7.5"
      cy="14"
      r="1.5"
      transform="rotate(90 7.5 14)"
      fill="#6D7172"
    />
    <circle
      cx="7.5"
      cy="14"
      r="1.5"
      transform="rotate(90 7.5 14)"
      fill="#6D7172"
    />
    <circle
      cx="7.5"
      cy="14"
      r="1.5"
      transform="rotate(90 7.5 14)"
      fill="#6D7172"
    />
    <circle
      cx="7.5"
      cy="8"
      r="1.5"
      transform="rotate(90 7.5 8)"
      fill="#6D7172"
    />
    <circle
      cx="7.5"
      cy="8"
      r="1.5"
      transform="rotate(90 7.5 8)"
      fill="#6D7172"
    />
    <circle
      cx="7.5"
      cy="8"
      r="1.5"
      transform="rotate(90 7.5 8)"
      fill="#6D7172"
    />
    <circle
      cx="7.5"
      cy="8"
      r="1.5"
      transform="rotate(90 7.5 8)"
      fill="#6D7172"
    />
    <circle
      cx="7.5"
      cy="2"
      r="1.5"
      transform="rotate(90 7.5 2)"
      fill="#6D7172"
    />
    <circle
      cx="7.5"
      cy="2"
      r="1.5"
      transform="rotate(90 7.5 2)"
      fill="#6D7172"
    />
    <circle
      cx="7.5"
      cy="2"
      r="1.5"
      transform="rotate(90 7.5 2)"
      fill="#6D7172"
    />
    <circle
      cx="7.5"
      cy="2"
      r="1.5"
      transform="rotate(90 7.5 2)"
      fill="#6D7172"
    />
    <circle
      cx="1.5"
      cy="14"
      r="1.5"
      transform="rotate(90 1.5 14)"
      fill="#6D7172"
    />
    <circle
      cx="1.5"
      cy="14"
      r="1.5"
      transform="rotate(90 1.5 14)"
      fill="#6D7172"
    />
    <circle
      cx="1.5"
      cy="14"
      r="1.5"
      transform="rotate(90 1.5 14)"
      fill="#6D7172"
    />
    <circle
      cx="1.5"
      cy="14"
      r="1.5"
      transform="rotate(90 1.5 14)"
      fill="#6D7172"
    />
    <circle
      cx="1.5"
      cy="8"
      r="1.5"
      transform="rotate(90 1.5 8)"
      fill="#6D7172"
    />
    <circle
      cx="1.5"
      cy="8"
      r="1.5"
      transform="rotate(90 1.5 8)"
      fill="#6D7172"
    />
    <circle
      cx="1.5"
      cy="8"
      r="1.5"
      transform="rotate(90 1.5 8)"
      fill="#6D7172"
    />
    <circle
      cx="1.5"
      cy="8"
      r="1.5"
      transform="rotate(90 1.5 8)"
      fill="#6D7172"
    />
    <circle
      cx="1.5"
      cy="2"
      r="1.5"
      transform="rotate(90 1.5 2)"
      fill="#6D7172"
    />
    <circle
      cx="1.5"
      cy="2"
      r="1.5"
      transform="rotate(90 1.5 2)"
      fill="#6D7172"
    />
    <circle
      cx="1.5"
      cy="2"
      r="1.5"
      transform="rotate(90 1.5 2)"
      fill="#6D7172"
    />
    <circle
      cx="1.5"
      cy="2"
      r="1.5"
      transform="rotate(90 1.5 2)"
      fill="#6D7172"
    />
  </svg>
);
