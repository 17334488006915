import {
  FieldErrorsImpl,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import OverviewColumn from './components/OverviewColumn/OverviewColumn';
import style from './CreateModuleNormalTab.module.scss';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import ParametersListColumn from './components/ParametersListColumn/ParametersListColumn';
import ParametersDetailsColumn from './components/ParametersDetailsColumn/ParametersDetailsColumn';

interface ICreateModuleNormalTab {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
}

const CreateModuleNormalTab = ({
  register,
  setValue,
  setError,
  clearErrors,
  errors,
}: ICreateModuleNormalTab) => {
  return (
    <CsbErrorBoundary>
      <div
        data-testid="CreateModuleNormalTab"
        className={style['column-container']}
      >
        <OverviewColumn
          register={register}
          setValue={setValue}
          setError={setError}
          clearErrors={clearErrors}
          errors={errors}
        />
        <ParametersListColumn />
        <ParametersDetailsColumn />
      </div>
    </CsbErrorBoundary>
  );
};

export default CreateModuleNormalTab;
